import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import CotroliaSelect from "../core/input/CotroliaSelect";
import { Clear } from "@material-ui/icons";
import { range } from "lodash";

const FilterYear: React.FC<{
  value?: string | number;
  onChange: (value?: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();
  const years = [...range(2015, currentYear + 1)];

  return (
    <FormControl variant="filled">
      <CotroliaInputLabel>{t("FilterYear")}</CotroliaInputLabel>
      <CotroliaSelect
        value={props.value ?? ""}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          props.onChange(e.target.value);
        }}
        endAdornment={
          props.value && (
            <InputAdornment position="end" style={{ marginRight: 20 }}>
              <IconButton onClick={() => props.onChange(undefined)}>
                <Clear />
              </IconButton>
            </InputAdornment>
          )
        }
      >
        {years.map((year) => (
          <MenuItem value={year} key={year}>
            {year}
          </MenuItem>
        ))}
      </CotroliaSelect>
    </FormControl>
  );
};

export default FilterYear;
