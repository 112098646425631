import { FormControl, FormControlProps, useTheme } from "@material-ui/core";
import React from "react";

const CotroliaFormControl: React.FC<FormControlProps> = ({
  style,
  ...props
}) => {
  const theme = useTheme();
  return (
    <FormControl
      fullWidth
      style={{ margin: `${theme.spacing(1)}px 0`, ...style }}
      {...props}
    >
      {props.children}
    </FormControl>
  );
};

export default CotroliaFormControl;
