import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import { useMenu } from "../../features/menu/hooks/useMenu";
import { useDevice } from "../../hooks/useDevice";

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing(8)}px ${theme.spacing(8)}px ${theme.spacing(
      4,
    )}px`,
    "& svg": {
      width: "31.5px",
      height: "31.5px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(3)}px`,
    },
  },
  rootBottom: {
    height: "150px",
    width: "100%",
    position: "absolute",
    top: "99%",
    left: "0",
    zIndex: 2,
    content: "''",
  },
  titleRightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: theme.spacing(2),
  },
  titleLeftContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2),
  },
  menuIcon: {
    cursor: "pointer",
  },
}));

const Header: React.FC<{
  variant?: string;
  focus: boolean;
  title: string;
  titleIcon?: React.ReactNode;
  titleDesc?: string;
}> = (props) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const menu = useMenu();
  const device = useDevice();

  const handleGoBack = () => {
    navigate(-1);
  };

  let background = "";
  let color = "";
  switch (props.variant) {
    case "primary":
      background = theme.palette.primary.main;
      color = theme.palette.primary.contrastText;
      break;
    case "secondary":
      background = theme.palette.secondary.main;
      color = theme.palette.secondary.contrastText;
      break;
    case "warning":
      background = theme.palette.warning.main;
      color = theme.palette.warning.contrastText;
      break;
    default:
      background = theme.palette.background.default;
      color = theme.palette.text.primary;
      break;
  }

  return (
    <Box className={classes.root} style={{ backgroundColor: background }}>
      {props.focus ? (
        <>
          {device.isMobile ? (
            <ArrowBackIcon style={{ color: color }} onClick={handleGoBack} />
          ) : (
            <Button
              variant="contained"
              onClick={handleGoBack}
              startIcon={<ArrowBackIcon />}
              style={{ backgroundColor: color, color: background }}
            >
              {t("GoBack")}
            </Button>
          )}
          <Box display="flex" alignItems="center" style={{ color: color }}>
            <Box className={classes.titleRightContainer}>
              <Typography variant="h4">{props.title}</Typography>
              <Typography variant="subtitle1">{props.titleDesc}</Typography>
            </Box>
            {props.titleIcon}
          </Box>
        </>
      ) : (
        <Box display="flex" alignItems="center" style={{ color: color }}>
          {device.isMobile ? (
            <MenuIcon
              className={classes.menuIcon}
              onClick={() => menu.toggleMenu()}
            />
          ) : (
            props.titleIcon
          )}
          <Box className={classes.titleLeftContainer}>
            <Typography variant={device.isMobile ? "subtitle2" : "h4"}>
              {props.title}
            </Typography>
            <Typography variant="subtitle2">{props.titleDesc}</Typography>
          </Box>
        </Box>
      )}
      {props.children}
      <span
        className={classes.rootBottom}
        style={{ backgroundColor: background }}
      />
    </Box>
  );
};

export default Header;
