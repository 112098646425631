import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import LanguageSelector from "../../../components/core/LanguageSelector";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const AuthFooter: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="caption" style={{ opacity: ".5", marginRight: 20 }}>
        Version {import.meta.env.VITE_VERSION}
      </Typography>
      <LanguageSelector />
    </div>
  );
};

export default AuthFooter;
