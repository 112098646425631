import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  formContainer: {
    width: 550,
    justifyContent: "center",
    background: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      width: "100%",
      borderRadius: 0,
    },
  },
}));

const AuthFormContainer: React.FC = (props) => {
  const classes = useStyle();
  return <div className={classes.formContainer}>{props.children}</div>;
};

export default AuthFormContainer;
