import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  container: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

const HTMLContainer = ({ children }) => {
  const classes = useStyle();
  return (
    <div
      dangerouslySetInnerHTML={{ __html: children }}
      className={classes.container}
    ></div>
  );
};

export default HTMLContainer;
