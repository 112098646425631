import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import CotroliaSelect from "../core/input/CotroliaSelect";
import { Clear } from "@material-ui/icons";
import { range } from "lodash";
import moment from "moment";

const FilterMonth: React.FC<{
  value?: string | number;
  onChange: (value?: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  const months = [...range(1, 13)];

  return (
    <FormControl variant="filled">
      <CotroliaInputLabel>{t("FilterMonth")}</CotroliaInputLabel>
      <CotroliaSelect
        value={props.value ?? ""}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          props.onChange(e.target.value);
        }}
        endAdornment={
          props.value && (
            <InputAdornment position="end" style={{ marginRight: 20 }}>
              <IconButton onClick={() => props.onChange(undefined)}>
                <Clear />
              </IconButton>
            </InputAdornment>
          )
        }
      >
        {months.map((month) => (
          <MenuItem value={month} key={month}>
            {moment()
              .month(month - 1)
              .format("MMMM")}
          </MenuItem>
        ))}
      </CotroliaSelect>
    </FormControl>
  );
};

export default FilterMonth;
