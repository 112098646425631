import { Grid, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    height: "100vh",
  },
}));

const FullPageLoader = () => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.backdrop}
      container
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
    </Grid>
  );
};

export default FullPageLoader;
