import { useCallback } from "react";

import * as Sentry from "@sentry/react";
import { User } from "../features/user/types/userTypes";

export const useSentry = (): {
  initSentry: () => void;
  setupSentryUser: (user?: User) => void;
} => {
  const initSentry = useCallback(
    () =>
      Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [],
        release: "cotrolia@" + import.meta.env.VITE_VERSION,
        enabled: !!import.meta.env.VITE_SENTRY_DSN,
        environment: window.location.hostname,
      }),
    [],
  );

  const setupSentryUser = useCallback((user?: User) => {
    if (user) {
      Sentry.setUser({
        email: user.email,
      });
    } else {
      Sentry.setUser(null);
    }
  }, []);

  return {
    initSentry,
    setupSentryUser,
  };
};
