import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import AppConstant from "../../../utils/appConstant";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyle = makeStyles((theme) => ({
  card: {
    width: AppConstant.notificationCardWidth,
    height: "100%",
    "& .MuiCardActions-root": {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      width: AppConstant.notificationCardWidthSm,
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(3),
  },
}));

const NotificationCardSkeleton = ({ className }) => {
  const classes = useStyle();

  return (
    <Card className={`${classes.card} ${className}`}>
      <CardContent>
        <Box className={classes.header}>
          <Typography variant="overline" style={{ flex: 1 }}>
            <Skeleton variant="text" />
          </Typography>
        </Box>
        <Typography variant="h4">
          <Skeleton variant="text" />
        </Typography>
        <Typography variant="body1">
          <Skeleton variant="text" />
        </Typography>
      </CardContent>
      <CardActions>
        <Skeleton variant="rect" width={100} height={30} />
      </CardActions>
    </Card>
  );
};

export default NotificationCardSkeleton;
