import { Navigate, Outlet, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import FullPageLoader from "../../../components/core/FullPageLoader";

let userReloaded = false;

export const RequireAuth: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const [update, forceUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (!userReloaded && !auth.user) {
      auth.fetchConnectedUser().then(() => forceUpdate(!update));
    }
    userReloaded = true;
  }, [auth, update]);

  return (
    <>
      {auth.user && <Outlet />}
      {!auth.user && userReloaded && (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
      {!auth.user && !userReloaded && <FullPageLoader />}
    </>
  );
};
