import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { useApi } from "../../../../../hooks/useApi";

const useStyle = makeStyles((theme) => ({
  container: {
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    padding: theme.spacing(3),
  },
  scrollbarComplementaryPart: {
    overflowY: "scroll",
    maxHeight: 320,
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "100%",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      borderRadius: "100vh",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: "100vh",
    },
  },
  content: {
    fontFamily: "Roboto, sans-serif",
    lineHeight: "24px",
    "& > * > a": {
      color: theme.palette.info.contrastText,
    },
  },
}));

const FieldComplementaryPartInfos: React.FC<{
  defaultInfos?: string;
}> = (props) => {
  const { call } = useApi();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const classes = useStyle();
  const { intervention, setIntervention } = useNewInterventionForm();

  const [isLoadingComplementaryPartInfos, setLoadingComplementaryPartInfos] =
    useState(false);

  const searchPackingInstructions = useCallback(
    (partType: string, lang: string, carBrand?: string, carModel?: string) => {
      let url = `/supercotrolia/packinginstructions?type=${partType}&lang=${lang}`;
      if (!!carBrand && !!carModel) {
        url += `&carBrand=${carBrand}&carModel=${carModel}`;
      }
      return call(url);
    },
    [call],
  );

  useEffect(() => {
    if (props.defaultInfos) {
      setIntervention((prev) => {
        return {
          ...prev,
          packaging: {
            ...prev.packaging,
            complementaryPartInfos: props.defaultInfos,
          },
        };
      });
    }
    setLoadingComplementaryPartInfos(true);
    searchPackingInstructions(
      intervention.part.type!,
      i18n.language,
      intervention.car.carBrand?.id,
      intervention.car.carModel?.id,
    )
      .then((res: string | string[] | undefined) => {
        if (res) {
          setIntervention((prev) => {
            return {
              ...prev,
              packaging: {
                ...prev.packaging,
                complementaryPartInfos: typeof res === "string" ? res : res[0],
              },
            };
          });
        }
      })
      .catch((error: unknown) => {
        console.error("Error fetching complementary part instructions:", error);
      })
      .finally(() => setLoadingComplementaryPartInfos(false));
  }, [
    i18n.language,
    intervention.car.carBrand?.id,
    intervention.car.carModel?.id,
    intervention.part.type,
    props.defaultInfos,
    searchPackingInstructions,
    setIntervention,
  ]);
  return (
    <>
      {isLoadingComplementaryPartInfos && <CircularProgress size={25} />}
      {!isLoadingComplementaryPartInfos &&
        !!intervention.packaging.complementaryPartInfos && (
          <Box
            className={classes.container}
            style={{ color: theme.palette.info.contrastText }}
          >
            <Grid
              container
              justifyContent="space-between"
              style={{ flexWrap: "nowrap", marginBottom: theme.spacing(3) }}
            >
              <Typography variant="h5">{t("ComplementaryPart")}</Typography>
              <WarningIcon style={{ width: 48, height: 48 }} />
            </Grid>
            <Box className={classes.scrollbarComplementaryPart}>
              <Box className={classes.content}>
                {!!intervention.packaging.complementaryPartInfos && (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        intervention.packaging.complementaryPartInfos.replace(
                          /(\r\n|\r|\n)/g,
                          "<br>",
                        ),
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        )}
    </>
  );
};

export default FieldComplementaryPartInfos;
