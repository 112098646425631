import { useCallback } from "react";
import { useApi } from "../../../hooks/useApi";
import { Invoice } from "../types/InvoiceTypes";

export const useInvoices = (): {
  searchInvoices: (
    limit: number,
    page: number,
    search: string[],
    order: Record<string, string>,
  ) => Promise<{ results: Invoice[]; total: number }>;
} => {
  const { call } = useApi();

  const searchInvoices = useCallback(
    (
      limit: number,
      page: number,
      search: string[],
      order: Record<string, string>,
    ): Promise<{ results: Invoice[]; total: number }> =>
      call("/supercotrolia/invoices", {
        data: {
          limit,
          page,
          search,
          order,
        },
      }),
    [call],
  );

  return {
    searchInvoices,
  };
};
