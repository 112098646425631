import { useApi } from "../../../hooks/useApi";
import { Notification } from "../types/NotificationTypes";

export const useNotifications = (): {
  getAllNotification: () => Promise<Notification[]>;
  readNotification: (id: string) => Promise<void>;
} => {
  const { call } = useApi();

  const getAllNotification = (): Promise<Notification[]> =>
    call("/supercotrolia/notifications");

  const readNotification = (id: string) =>
    call("/supercotrolia/notifications/" + id, { method: "POST" });

  return {
    getAllNotification,
    readNotification,
  };
};
