import { useCallback } from "react";
import { useApi } from "../../../hooks/useApi";
import { Address } from "../../companies/types/CompaniesTypes";
import { User } from "../types/userTypes";

export const useUsers = (): {
  getCompanyUsers: (socid: string) => Promise<User[]>;
  updateMe: (
    lastname: string,
    firstname: string,
    email: string,
  ) => Promise<void>;
  updateMyLanguage: (languageCode: string) => Promise<void>;
  updateMyCompany: (
    name: string,
    phone: string,
    address: Address,
    vat: string,
  ) => Promise<void>;
} => {
  const { call } = useApi();

  const getCompanyUsers = useCallback(
    (socid: string): Promise<User[]> =>
      call("/supercotrolia/users?socid=" + socid),
    [call],
  );

  const updateMe = (lastname: string, firstname: string, email: string) =>
    call("/supercotrolia/me", {
      data: { lastname, firstname, email },
      method: "PUT",
    });

  const updateMyLanguage = (languageCode: string) =>
    call("/supercotrolia/me/language", {
      data: { languageCode },
      method: "PUT",
    });

  const updateMyCompany = (
    name: string,
    phone: string,
    address: Address,
    vat: string,
  ) =>
    call("/supercotrolia/me/company", {
      data: { name, phone, address, vat },
      method: "PUT",
    });

  return {
    getCompanyUsers,
    updateMe,
    updateMyLanguage,
    updateMyCompany,
  };
};
