import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingButton = ({
  isLoading,
  loadingLabel,
  originalLabel,
  children,
  ...buttonProps
}) => (
  <Button
    {...buttonProps}
    disabled={isLoading || buttonProps.disabled}
    onClick={(e) => {
      if (isLoading) return;
      buttonProps.onClick?.(e);
    }}
  >
    {isLoading ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <CircularProgress color="inherit" size={16} />
        <span style={{ marginLeft: 8 }}>{loadingLabel ?? originalLabel}</span>
      </div>
    ) : (
      children
    )}
  </Button>
);

export default LoadingButton;
