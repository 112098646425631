import { useEffect, useState } from "react";
import { Box, Collapse, makeStyles, useTheme } from "@material-ui/core";
import DividerWithIcon from "./DividerWithAction";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@material-ui/icons/Visibility";
import NotificationCard from "./NotificationCard";
import NotificationCardSkeleton from "./skeleton/NotificationCardSkeleton";
import { useAuth } from "../../features/auth/hooks/useAuth";
import { useNotifications } from "../../features/notifications/hooks/useNotifications";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  cardContainer: {
    display: "flex",
    overflowX: "scroll",
    flexWrap: "nowrap",
    gap: theme.spacing(2),
    margin: theme.spacing(2, -8),
    padding: theme.spacing(0, 8),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, -2),
      padding: theme.spacing(0, 2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

const NotificationDashboardList = () => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);
  const classes = useStyle();
  const [isLoading, setLoading] = useState(true);
  const [notificationNb, setNotificationNb] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const { user } = useAuth();
  const { getAllNotification } = useNotifications();

  const hideList = () => {
    setHidden((hidden) => !hidden);
  };

  // Get all notifications for current user
  useEffect(() => {
    getAllNotification()
      .then((res) => {
        setNotificationData(res.notifications);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [user.notificationNb]);

  // Set notification numbers for skeletons
  useEffect(() => {
    if (user && user.thirdparty) {
      setNotificationNb(user.notificationNb);
    }
  }, [user]);

  const displayCards = () => {
    let notifications = [];

    if (isLoading) {
      // Show skeletons while loading
      for (let i = 0; i < notificationNb; i++) {
        notifications.push(<NotificationCardSkeleton />);
      }
    } else {
      // Draw notifications card
      notificationData.forEach((el) => {
        notifications.push(<NotificationCard data={el} />);
      });
    }

    return (
      <>
        {notifications.map((item, index) => (
          <Box key={index} className={classes.notifCardMargin}>
            {item}
          </Box>
        ))}
      </>
    );
  };

  return (
    <Box className={classes.root}>
      <DividerWithIcon
        title={t("Informations")}
        icon={<VisibilityIcon />}
        action={hideList}
        actionName={hidden ? t("Show") : t("Hide")}
      />
      <Collapse in={!hidden}>
        <Box className={classes.cardContainer}>{displayCards()}</Box>
      </Collapse>
    </Box>
  );
};

export default NotificationDashboardList;
