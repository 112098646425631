import React, { useState } from "react";
import { useConfiguration } from "../../hooks/useConfiguration";
import { makeStyles, MenuItem } from "@material-ui/core";
import CotroliaSelect from "./input/CotroliaSelect";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../features/auth/hooks/useAuth";
import { useUsers } from "../../features/user/hooks/useUsers";

const useStyles = makeStyles((theme) => ({
  img: {
    width: 15,
    height: 15,
    marginRight: 5,
    marginLeft: 5,
    borderRadius: "50%",
  },
  entry: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const configuration = useConfiguration();
  const { user, setUser } = useAuth();
  const { updateMyLanguage } = useUsers();
  const classes = useStyles();

  const handleChange = async (e) => {
    const newLang = e.target.value;
    i18n.changeLanguage(newLang).then(async () => {
      if (user) {
        // Update user language only if connected
        await updateMyLanguage(newLang);
        setUser({ ...user, language: newLang });
      }
      await configuration.refresh();
    });
  };

  return (
    <>
      {configuration.availableLangs &&
        configuration.availableLangs.length > 1 && (
          <CotroliaSelect value={i18n.language} onChange={handleChange}>
            {configuration.availableLangs.map((elem) => (
              <MenuItem
                value={elem.code}
                name={elem.code}
                className={classes.entry}
                key={elem.code}
              >
                <img
                  src={`/logo/${elem.code.substring(3, 5)}.png`}
                  className={classes.img}
                  alt={`${elem.label} flag`}
                ></img>
                {elem.label}
              </MenuItem>
            ))}
          </CotroliaSelect>
        )}
    </>
  );
};

export default LanguageSelector;
