import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useConfiguration } from "../../../../../hooks/useConfiguration";

const FieldPackingInstructions: React.FC<{ lottieSrc?: string }> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { interventionConfig } = useConfiguration();
  const classes = makeStyles({
    text: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      lineHeight: 2,
      "& ol": {
        margin: 0,
        padding: 0,
        paddingLeft: 20,
      },
    },
  })();

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      {props.lottieSrc && (
        <Grid container item xs={12} md={5} justifyContent="center">
          <DotLottieReact
            src={props.lottieSrc}
            loop
            autoplay
            width={1080}
            height={1080}
          />
        </Grid>
      )}

      <Grid container item xs={12} md={props.lottieSrc ? 7 : 12}>
        <Typography variant="h5" style={{ color: theme.palette.text.primary }}>
          {t("PackingInstructions")}
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing(3),
          }}
        >
          {t("PackingInstructionsInfo")}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          <Box
            dangerouslySetInnerHTML={{
              __html: interventionConfig.packingInstruction,
            }}
          />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FieldPackingInstructions;
