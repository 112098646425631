import React, { CSSProperties } from "react";

import { Stepper, Step, StepLabel, Theme, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      "& .MuiStepLabel-labelContainer": {
        display: "none",
      },
    },
  },
  primary: {
    "& .MuiStepIcon-root, & .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        "&.MuiStepIcon-root": {
          color: theme.palette.grey[800],
          fontWeight: 700,
        },
        "&.MuiStepIcon-completed": {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.common.white,
          borderRadius: "100%",
          fontWeight: 700,
        },
        "&.MuiStepIcon-active": {
          color: theme.palette.primary.main,
        },
        "& .MuiStepIcon-text": {
          fill: theme.palette.primary.contrastText,
        },
      },
    "& .MuiStepLabel-completed, & .MuiStepLabel-active, ": {
      fontWeight: 400,
    },
    "& .MuiStepConnector-completed .MuiStepConnector-line, & .MuiStepConnector-active .MuiStepConnector-line, ":
      {
        borderColor: theme.palette.primary.main,
      },
  },
  secondary: {
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: theme.palette.secondary.main,
        "& .MuiStepIcon-text": {
          fill: theme.palette.secondary.contrastText,
        },
      },
  },
  error: {
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: theme.palette.error.main,
        "& .MuiStepIcon-text": {
          fill: theme.palette.error.contrastText,
        },
      },
  },
  warning: {
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: theme.palette.warning.main,
        "& .MuiStepIcon-text": {
          fill: theme.palette.warning.contrastText,
        },
      },
  },
  info: {
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: theme.palette.info.main,
        "& .MuiStepIcon-text": {
          fill: theme.palette.info.contrastText,
        },
      },
  },
  success: {
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: theme.palette.success.main,
        "& .MuiStepIcon-text": {
          fill: theme.palette.success.contrastText,
        },
      },
  },
}));

export interface CotroliaStepperStep {
  key: string;
  label: string;
}

const CotroliaStepper: React.FC<{
  steps: CotroliaStepperStep[];
  activeStep: number;
  variant: "primary" | "secondary";
  style?: CSSProperties;
}> = (props) => {
  const theme = useTheme();
  const classes = useStyle(theme);

  return (
    <Stepper
      alternativeLabel
      activeStep={props.activeStep}
      className={classes.root}
      style={props.style}
    >
      {props.steps.map((step) => (
        <Step key={step.key} className={classes[props.variant]}>
          <StepLabel>{step.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CotroliaStepper;
