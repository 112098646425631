import { Button, useTheme } from "@material-ui/core";
import React from "react";

const CircleButton = (props) => {
  const theme = useTheme();
  return (
    <Button
      style={{
        borderRadius: theme.spacing(10),
        padding: theme.spacing(1),
        minWidth: theme.spacing(5),
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default CircleButton;
