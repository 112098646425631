import React from "react";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import {
  Box,
  FormHelperText,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import UploadButton from "../../../../../components/core/UploadButton";

const useStyle = makeStyles((theme) => ({
  uploadContainer: { width: "100%" },
  mt: {
    marginTop: theme.spacing(1),
  },
}));

const FieldPartPictures: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyle();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <Box className={classes.uploadContainer}>
      <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
        {t("DocumentOrPicture")}
      </Typography>
      <Box className={classes.mt}>
        <UploadButton
          parentFiles={intervention.part.pictures}
          setParentFiles={(files: File[]) => {
            setIntervention({
              ...intervention,
              part: {
                ...intervention.part,
                pictures: files,
              },
            });
          }}
          label={t("PictureButtonLabel")}
          error={undefined}
          errorMsg={undefined}
          id={"upload-file-pictures"}
        />
      </Box>
      <FormHelperText
        variant="outlined"
        style={{ margin: 0, marginTop: theme.spacing(1) }}
      >
        {t("DocumentOrPictureHelper")}
      </FormHelperText>
    </Box>
  );
};

export default FieldPartPictures;
