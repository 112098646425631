import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import CotroliaInputLabel from "../../../../../components/core/input/CotroliaInputLabel";
import CotroliaSelect from "../../../../../components/core/input/CotroliaSelect";
import { FormHelperText } from "@material-ui/core";
import { useConfiguration } from "../../../../../hooks/useConfiguration";

const FieldPartType: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { interventionConfig } = useConfiguration();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl variant="filled" required error={!!props.error}>
      <CotroliaInputLabel id="type-label" error={!!props.error}>
        {t("PartType")}
      </CotroliaInputLabel>
      <CotroliaSelect
        id="type"
        labelId="type-label"
        value={intervention.part.type}
        native
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setIntervention({
            ...intervention,
            part: {
              ...intervention.part,
              type: e.target.value,
            },
          });
          props.onChange && props.onChange();
        }}
      >
        <option value="" />
        {interventionConfig.partType &&
          Object.keys(interventionConfig.partType)
            .sort((a, b) =>
              interventionConfig.partType[a].label.localeCompare(
                interventionConfig.partType[b].label,
              ),
            )
            .map((key) => (
              <optgroup
                label={interventionConfig.partType[key].label}
                key={key}
              >
                {interventionConfig.partType[key].values &&
                  Object.keys(interventionConfig.partType[key].values)
                    .sort((subkey1, subkey2) =>
                      interventionConfig.partType[key].values[
                        subkey1
                      ].localeCompare(
                        interventionConfig.partType[key].values[subkey2],
                      ),
                    )
                    .map((subkey) => (
                      <option key={subkey} value={subkey}>
                        {interventionConfig.partType[key].values[subkey]}
                      </option>
                    ))}
              </optgroup>
            ))}
      </CotroliaSelect>
      {props.error && (
        <FormHelperText variant="outlined">{props.error}</FormHelperText>
      )}
    </InterFormControl>
  );
};

export default FieldPartType;
