import React, { useEffect, useState } from "react";
import Content from "../components/core/layout/Content";
import Header from "../components/core/Header";
import HeaderActions from "../components/core/HeaderActions";
import { useTranslation } from "react-i18next";
import { Description, FilterList } from "@material-ui/icons";
import { useDevice } from "../hooks/useDevice";
import CircleButton from "../components/core/CircleButton";
import { Box, Button, Collapse, makeStyles } from "@material-ui/core";
import Datagrid from "../components/core/Datagrid";
import NoInvoice from "../features/invoices/components/NoInvoice";
import InvoicesFilters from "../features/invoices/components/InvoicesFilters";
import { Invoice } from "../features/invoices/types/InvoiceTypes";
import { useInvoices } from "../features/invoices/hooks/useInvoices";
import NoFilterResult from "../components/core/NoFilterResult";

const Invoices: React.FC = () => {
  const classes = makeStyles((theme) => ({
    root: {
      backgroundColor: "white",
      padding: theme.spacing(4),
      boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
      borderRadius: theme.spacing(0.5),
      justifyContent: "center",
    },
  }))();
  const { t } = useTranslation();
  const device = useDevice();
  const [page, setPage] = useState(1);
  const rowsPerPage = 50;
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<string[]>([]);
  const someFilters = Object.values(filters).some((value) => !!value);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [displayFilter, setDisplayFilter] = useState(false);
  const { searchInvoices } = useInvoices();

  useEffect(() => {
    setLoading(true);
    searchInvoices(rowsPerPage, page, filters, { tms: "DESC" })
      .then((res) => {
        setLoading(false);
        setInvoices(res.results);
        setTotal(res.total);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [filters, page, rowsPerPage, searchInvoices]);
  return (
    <>
      <Header
        focus={false}
        title={t("MenuInvoices")}
        titleIcon={<Description />}
      >
        <HeaderActions>
          {device.isMobile ? (
            <>
              <CircleButton
                color="primary"
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                <FilterList />
              </CircleButton>
            </>
          ) : (
            <>
              <Button
                color="primary"
                endIcon={<FilterList />}
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                {t("ToggleFiltersLabel")}
              </Button>
            </>
          )}
        </HeaderActions>
      </Header>
      <Content>
        {(total > 0 || someFilters) && (
          <Collapse in={displayFilter}>
            <InvoicesFilters applyFilters={setFilters} />
          </Collapse>
        )}
        {!isLoading && total === 0 ? (
          <>
            {someFilters ? (
              <NoFilterResult />
            ) : (
              <Box className={classes.root}>
                <NoInvoice />
              </Box>
            )}
          </>
        ) : (
          <Datagrid
            rows={invoices.map((invoice) => {
              return { ...invoice };
            })}
            rowsPerPage={rowsPerPage}
            page={page}
            total={total}
            enablePageSelector
            onChangePage={(p) => setPage(p)}
            columnsDef={[
              { header: t("InvoicesHeaderRef"), format: (value) => value.ref },
              {
                header: t("InvoicesHeaderState"),
                format: (value) => value.status,
              },
              {
                header: t("InvoicesHeaderRefInter"),
                format: (value) => value.intervention.ref,
              },
              {
                header: t("InvoicesHeaderDateCreation"),
                format: (value) => value.intervention.dateCreat,
              },
              {
                header: t("InvoicesHeaderDistriName"),
                format: (value) => value.distributor.name,
              },
              {
                header: t("InvoicesHeaderDistriCode"),
                format: (value) => value.distributor.distributerCode,
              },
              {
                header: t("InvoicesHeaderGarageName"),
                format: (value) => value.garage.name,
              },
              {
                header: t("InvoicesHeaderClientCode"),
                format: (value) => value.garage.customerCode,
              },
              {
                header: t("InvoicesHeaderShopCode"),
                format: (value) => value.garage.shopCode,
              },
              {
                header: t("InvoicesHeaderDateIssue"),
                format: (value) => value.issueDate,
              },
              {
                header: t("InvoicesHeaderDateDue"),
                format: (value) => value.dueDate,
              },
              {
                header: t("InvoicesHeaderAmount"),
                format: (value) => value.amount,
              },
            ]}
            loading={isLoading}
          />
        )}
      </Content>
    </>
  );
};

export default Invoices;
