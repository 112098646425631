import React, { CSSProperties } from "react";

import { Box, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
}));

const CotroliaStepperActions: React.FC<{ style?: CSSProperties }> = (props) => {
  const classes = useStyle();
  return (
    <Box className={classes.btnContainer} {...props}>
      {props.children}
    </Box>
  );
};

export default CotroliaStepperActions;
