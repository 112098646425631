import React, { useState } from "react";
import {
  ContentTwoSided,
  LeftSide,
  RightSide,
} from "../../../../../components/core/ContentTwoSided";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import FieldPartType from "../../fields/part/FieldPartType";
import FieldReference from "../../fields/part/FieldReference";
import FieldPartPictures from "../../fields/part/FieldPartPictures";
import { useStepValidator } from "../../../hooks/useStepValidator";
import ToggleSection from "../../../../../components/core/ToggleSection";
import { useTranslation } from "react-i18next";
import MemoryIcon from "@material-ui/icons/Memory";
import BuildIcon from "@material-ui/icons/Build";
import FieldSecurityCode from "../../fields/car/FieldSecurityCode";
import FieldFrequency from "../../fields/common/FieldFrequency";
import FieldCustomerEffect from "../../fields/car/FieldCustomerEffect";
import FieldDiagnostic from "../../fields/car/FieldDiagnostic";
import FieldPartsReplaced from "../../fields/car/FieldPartsReplaced";
import FieldPartDiagFiles from "../../fields/part/FieldPartDiagFiles";

interface StepPartCotroliaErrors {
  typeError?: string;
  refError?: string;
  customerEffectError?: string;
  diagnosticError?: string;
  partsReplacedError?: string;
}

const StepPartCotrolia: React.FC = () => {
  const { intervention } = useNewInterventionForm();
  const { t } = useTranslation();
  const [enablePartsReplacedValidation, setEnablePartsReplacedValidation] =
    useState<boolean>(!!intervention.car.parts_replaced);
  const [errors, setErrors] = useState<StepPartCotroliaErrors>({});

  useStepValidator(() => {
    const stepErrors: StepPartCotroliaErrors = {
      typeError:
        !intervention.part.type || intervention.part.type === "0"
          ? t("RequiredFieldTypeError")
          : undefined,
      refError:
        !intervention.part.ref || !intervention.part.ref.ref
          ? t("RequiredFieldRefError")
          : undefined,
      customerEffectError: !intervention.car.customer_effect
        ? t("RequiredFieldCustomerEffectError")
        : undefined,
      diagnosticError: !intervention.car.diagnostic
        ? t("RequiredFieldDiagnosticError")
        : undefined,
      partsReplacedError:
        enablePartsReplacedValidation && !intervention.car.parts_replaced
          ? t("RequiredFieldPartsReplacedError")
          : undefined,
    };
    setErrors(stepErrors);
    return (
      !stepErrors.typeError &&
      !stepErrors.refError &&
      !stepErrors.customerEffectError &&
      !stepErrors.diagnosticError &&
      !stepErrors.partsReplacedError
    );
  });

  return (
    <div>
      <ToggleSection
        title={t("PartSectionType")}
        description={t("PartSectionTypeInfo")}
        icon={<MemoryIcon />}
        defaultExpanded={true}
      >
        <ContentTwoSided>
          <LeftSide>
            <FieldPartType
              error={errors.typeError}
              onChange={() => {
                setErrors({ ...errors, typeError: undefined });
              }}
            />
            <FieldReference
              error={errors.refError}
              onChange={() => {
                setErrors({ ...errors, refError: undefined });
              }}
            />
            <FieldSecurityCode />
          </LeftSide>
          <RightSide>
            <FieldPartPictures />
          </RightSide>
        </ContentTwoSided>
      </ToggleSection>
      <ToggleSection
        title={t("PartSectionFailureDesc")}
        description={t("PartSectionFailureInfoCotrolia")}
        icon={<BuildIcon />}
        defaultExpanded={true}
      >
        <ContentTwoSided>
          <LeftSide>
            <FieldFrequency />
            <FieldCustomerEffect
              error={errors.customerEffectError}
              onChange={() =>
                setErrors({ ...errors, customerEffectError: undefined })
              }
            />
            <FieldDiagnostic
              error={errors.diagnosticError}
              onChange={() =>
                setErrors({ ...errors, diagnosticError: undefined })
              }
            />
          </LeftSide>
          <RightSide>
            <FieldPartsReplaced
              error={errors.partsReplacedError}
              onChange={(checked: boolean) => {
                setEnablePartsReplacedValidation(checked);
                setErrors({ ...errors, partsReplacedError: undefined });
              }}
            />
            <FieldPartDiagFiles />
          </RightSide>
        </ContentTwoSided>
      </ToggleSection>
    </div>
  );
};

export default StepPartCotrolia;
