import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    padding: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ContentCard = (props) => {
  const classes = useStyle();
  return (
    <Box className={classes.root} {...props}>
      {props.children}
    </Box>
  );
};

export default ContentCard;
