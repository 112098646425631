import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import SideMenu from "../features/menu/components/SideMenu";
import { useMenu } from "../features/menu/hooks/useMenu";
import AppConstant from "../utils/appConstant";
import useCustomTheme from "../hooks/useCustomTheme";
import { useDevice } from "../hooks/useDevice";

const useStyles = makeStyles(() => ({
  root: {
    margin: "0",
    display: "flex",
    minHeight: "100vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "100%",
    boxSizing: "border-box",
  },
}));

const AuthenticatedApp = () => {
  const classes = useStyles();
  const menu = useMenu();
  const device = useDevice();
  const [containerWidth, setContainerWidth] = useState("100vw");
  const { menuTheme } = useCustomTheme();

  useEffect(() => {
    let drawerWidth = 0;
    if (!device.isMobile) {
      drawerWidth = menu.isOpen
        ? AppConstant.drawerWidth
        : AppConstant.drawerWidthClose;
    }
    setContainerWidth(`calc(100vw - ${drawerWidth}px)`);
  }, [menu.isOpen, device.isMobile, menuTheme]);

  return (
    <div className="App">
      <div className={classes.root}>
        <ThemeProvider theme={menuTheme}>
          <SideMenu />
        </ThemeProvider>
        <main className={classes.content} style={{ maxWidth: containerWidth }}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AuthenticatedApp;
