import React from "react";
import { makeStyles, Typography, Box, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const useStyle = makeStyles((theme) => ({
  entryContainer: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1) + 2,
  },
  entryLabel: {
    flexGrow: 1,
  },
  entryBtn: {
    color: theme.palette.text.secondary,
  },
}));

const AddContactEntry = ({ contact, handleEdit, handleDelete }) => {
  const classes = useStyle();
  return (
    <>
      <Box className={classes.entryContainer}>
        <Box className={classes.entryLabel}>
          <Typography variant="body1">
            {contact.lastname + " " + contact.firstname}
          </Typography>
          <Typography variant="body2">{contact.email}</Typography>
          <Typography variant="caption">{contact.phone}</Typography>
        </Box>

        <IconButton className={classes.entryBtn} onClick={handleEdit}>
          <EditIcon />
        </IconButton>

        <IconButton className={classes.entryBtn} onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default AddContactEntry;
