import React from "react";
import AppConstant from "../../../../utils/appConstant";
import NotificationCard from "../../../../components/core/NotificationCard";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { useAuth } from "../../../auth/hooks/useAuth";
import { useConfiguration } from "../../../../hooks/useConfiguration";

const InterventionDetailNotifications = ({
  status,
  paymentMethod,
  technicalInfo,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const configuration = useConfiguration();
  const { user } = useAuth();

  const displayNotificationByStatus = () => {
    let data = {};
    switch (status) {
      case AppConstant.interStatusQuoteReceive:
        data = {
          variant: "warning",
          title: t("PropalNotificationTitle"),
          description: t("PropalNotificationDescription"),
          short_description: t("PropalNotificationShortDescription"),
          icon: "payments",
          accept_label: t("PropalNotificationAcceptLabel"),
          accept_action: location.pathname + "/proposal",
        };
        return (
          <NotificationCard data={data} fullWidth withoutRead withRouter />
        );
      case AppConstant.interStatusPaymentPending:
        if (configuration.enablePayment) {
          data = {
            variant: "warning",
            title: t("InvoiceNotificationTitle"),
            description: t("InvoiceNotificationDescription"),
            short_description: t("InvoiceNotificationShortDescription"),
            icon: "payments",
            accept_label: t("InvoiceNotificationAcceptLabel"),
            accept_action: location.pathname + "/invoice",
          };
          return (
            (paymentMethod === null || parseInt(paymentMethod) === 0) && (
              <NotificationCard data={data} fullWidth withoutRead withRouter />
            )
          );
        } else {
          return <></>;
        }
      case AppConstant.interStatusRepair:
        if (!user?.thirdparty?.directPay && configuration.enablePayment) {
          data = {
            variant: "success",
            title: t("InvoiceNotificationTitle"),
            description: t("InvoicePaymentDifferNotificationDescription"),
            short_description: t("InvoiceNotificationShortDescription"),
            icon: "payments",
            accept_label: t("InvoiceNotificationAcceptLabel"),
            accept_action: location.pathname + "/invoice-differed",
          };
          return (
            (paymentMethod === null || parseInt(paymentMethod) === 0) && (
              <NotificationCard data={data} fullWidth withoutRead withRouter />
            )
          );
        } else {
          return <></>;
        }
      case AppConstant.interStatusDeclined:
        data = {
          variant: "error",
          title: t("TechnicienNotificationTitle"),
          description: technicalInfo,
          short_description: t("InvoiceNotificationShortDescription"),
          icon: "warning",
        };
        return (
          <NotificationCard data={data} fullWidth withoutRead withRouter />
        );
      default:
        return <></>;
    }
  };

  return (
    <Box style={{ marginBottom: 16 }}>{displayNotificationByStatus()}</Box>
  );
};

export default InterventionDetailNotifications;
