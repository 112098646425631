import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { useEffect } from "react";
import { formatSize } from "../../utils/validation";
import { FileUploadIcon } from "../icons/FileUploadIcon";

const DocumentEntry = ({ index, file, removeFile, nested }) => {
  const classes = makeStyles((theme) => ({
    entryContainer: {
      display: "flex",
      alignItems: "center",
      padding: `0 ${theme.spacing(2)}px`,
    },
    entryLabel: {
      flexGrow: 1,
    },
    entryBtn: {
      color: theme.palette.text.secondary,
    },
  }))();

  const handleClick = () => {
    removeFile(index);
  };

  return (
    <Box className={classes.entryContainer}>
      <Typography variant="body2" className={classes.entryLabel}>
        {file.name}
      </Typography>
      <Typography variant="body2">{formatSize(file.size)}</Typography>
      <IconButton className={classes.entryBtn} onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

const UploadButton = ({
  parentFiles,
  setParentFiles,
  label,
  error,
  errorMsg,
  id,
  nested = false,
}) => {
  const classes = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    btn: {
      width: "100%",
    },
    input: {
      display: "none",
    },
    listEntryContainer: {
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[nested ? 50 * 2 : 50],
      borderRadius: theme.spacing(1) + 2,
      position: "relative",
    },
    listEntryContainerError: {
      "&::before": {
        content: "' '",
        borderWidth: "80px",
        position: "absolute",
        left: "10px",
        bottom: 0,
        height: "2px",
        width: "calc(100% - 20px)",
        borderBottom: "2px solid " + theme.palette.error.main,
      },
    },
    errorContainer: {
      margin: `0 ${theme.spacing(2)}px`,
    },
  }))();
  const { t } = useTranslation();
  const [files, setFiles] = useState(parentFiles);

  useEffect(() => {
    setFiles(parentFiles);
  }, [parentFiles]);

  const handleChange = (event) => {
    const selectedFiles = event.target.files;

    let fileList = files.slice();
    for (let i = 0; i < selectedFiles.length; i++) {
      fileList.push(selectedFiles[i]);
    }

    setFiles(fileList);
    setParentFiles(fileList);
  };

  const removeFile = (index) => {
    let fileList = files.slice();
    fileList.splice(index, 1);
    setFiles(fileList);
    setParentFiles(fileList);
  };

  return (
    <Box className={classes.root}>
      <Box
        className={`${classes.listEntryContainer} ${
          error ? classes.listEntryContainerError : ""
        }`}
      >
        {files &&
          files.map((item, index) => (
            <DocumentEntry
              key={index}
              index={index}
              file={item}
              removeFile={removeFile}
              nested={nested}
            ></DocumentEntry>
          ))}
      </Box>

      <input
        accept="image/*,.pdf"
        className={classes.input}
        id={id}
        type="file"
        multiple
        onChange={handleChange}
      />
      <label htmlFor={id}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FileUploadIcon style={{ height: 24, width: 24 }} />}
          className={classes.btn}
          component="span"
          style={{ height: 40 }}
        >
          {label ? label : t("UploadDocument")}
        </Button>
      </label>

      <Typography
        variant="caption"
        color="error"
        className={classes.errorContainer}
      >
        {errorMsg}
      </Typography>
    </Box>
  );
};

export default UploadButton;
