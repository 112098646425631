import React from "react";
import { useState } from "react";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import FieldChassis from "../../fields/car/FieldChassis";
import FieldCarBrand from "../../fields/car/FieldCarBrand";
import FieldCarModel from "../../fields/car/FieldCarModel";
import FieldDistance from "../../fields/car/FieldDistance";
import FieldRegistration from "../../fields/car/FieldRegistration";
import { useStepValidator } from "../../../hooks/useStepValidator";
import FieldWarranty from "../../fields/part/FieldWarranty";
import ContentTwoSided, {
  LeftSide,
  RightSide,
} from "../../../../../components/core/ContentTwoSided";
import { useTranslation } from "react-i18next";
import { WarrantyValue } from "../../../../interventions/types/InterventionTypes";
import { useInterventions } from "../../../../interventions/hooks/useInterventions";
import { useConfiguration } from "../../../../../hooks/useConfiguration";

interface StepCarErrors {
  warrantyPrevInterError?: string;
  carBrandError?: string;
  carModelError?: string;
  frameError?: string;
  registrationError?: string;
  distanceError?: string;
}

const StepCarCotrolia: React.FC = () => {
  const { t } = useTranslation();
  const { getCarBrandFromModel } = useConfiguration();
  const { intervention, setIntervention } = useNewInterventionForm();
  const { getIntervention } = useInterventions();
  const [errors, setErrors] = useState<StepCarErrors>({});

  useStepValidator(() => {
    const stepErrors: StepCarErrors = {
      warrantyPrevInterError:
        intervention.part.waranty === WarrantyValue.Yes &&
        !intervention.part.warrantyPrevInter
          ? t("RequiredFieldWarrantyPrevInterError")
          : undefined,
      frameError: !intervention.car.frame
        ? t("RequiredFieldFrameError")
        : undefined,
      carBrandError: !intervention.car.carBrand
        ? t("RequiredFieldCarBrandError")
        : undefined,
      carModelError: !intervention.car.carModel
        ? t("RequiredFieldCarModelError")
        : undefined,
      registrationError: !intervention.car.registration
        ? t("RequiredFieldRegistrationError")
        : undefined,
      distanceError: !intervention.car.distance
        ? t("RequiredFieldDistanceError")
        : undefined,
    };
    setErrors(stepErrors);
    return (
      !stepErrors.warrantyPrevInterError &&
      !stepErrors.carBrandError &&
      !stepErrors.carModelError &&
      !stepErrors.frameError &&
      !stepErrors.registrationError &&
      !stepErrors.distanceError
    );
  });

  return (
    <>
      <ContentTwoSided>
        <LeftSide>
          <FieldWarranty
            error={errors.warrantyPrevInterError}
            onChange={(waranty, warrantyPrevInter) => {
              if (waranty === WarrantyValue.Yes && warrantyPrevInter?.id) {
                getIntervention(warrantyPrevInter.id)
                  .then((res) => {
                    setIntervention({
                      ...intervention,
                      car: {
                        ...intervention.car,
                        frame: res.frame,
                        registration: res.registration,
                        carBrand: getCarBrandFromModel(res.carType),
                        carModel: res.carType,
                      },
                      part: {
                        ...intervention.part,
                        waranty: waranty,
                        warrantyPrevInter: warrantyPrevInter,
                        type: res.partType?.id,
                        ref: { id: res.partRef, ref: "" },
                      },
                    });
                  })
                  .catch(() => {
                    console.error(
                      "Can’t get intervention information with id : " +
                        warrantyPrevInter.id,
                    );
                  });
              }
              if (intervention.part.warrantyPrevInter && !warrantyPrevInter) {
                setIntervention({
                  ...intervention,
                  car: {
                    ...intervention.car,
                    frame: "",
                    registration: "",
                    carBrand: undefined,
                    carModel: undefined,
                  },
                  part: {
                    ...intervention.part,
                    waranty: waranty,
                    warrantyPrevInter: warrantyPrevInter,
                    type: undefined,
                    ref: undefined,
                  },
                });
              }
              setErrors({
                ...errors,
                warrantyPrevInterError: undefined,
                frameError: undefined,
                registrationError: undefined,
                carBrandError: undefined,
                carModelError: undefined,
                distanceError: undefined,
              });
            }}
          />
          <FieldCarBrand
            error={errors.carBrandError}
            onChange={() => {
              setErrors({ ...errors, carBrandError: undefined });
            }}
          />
          <FieldCarModel
            error={errors.carModelError}
            onChange={() => {
              setErrors({ ...errors, carModelError: undefined });
            }}
          />
        </LeftSide>

        <RightSide>
          <FieldChassis
            error={errors.frameError}
            onChange={() => {
              setErrors({ ...errors, frameError: undefined });
            }}
          />
          <FieldRegistration
            error={errors.registrationError}
            onChange={() =>
              setErrors({ ...errors, registrationError: undefined })
            }
          />
          <FieldDistance
            error={errors.distanceError}
            onChange={() => setErrors({ ...errors, distanceError: undefined })}
          />
        </RightSide>
      </ContentTwoSided>
    </>
  );
};

export default StepCarCotrolia;
