import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/core/Header";
import CircleButton from "../components/core/CircleButton";
import { Button, makeStyles } from "@material-ui/core";
import HeaderActions from "../components/core/HeaderActions";
import CloseIcon from "@material-ui/icons/Close";
import Content from "../components/core/layout/Content";
import { useDevice } from "../hooks/useDevice";
import { Add, Cancel, Edit, Warning } from "@material-ui/icons";
import GarageForm from "../features/garages/components/GarageForm";
import { useGarages } from "../features/garages/hooks/useGarages";
import { GarageEdit } from "../features/garages/types/GarageTypes";
import i18next from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AlertCustomOptions, useAlert } from "react-alert";
import { ApiError } from "../context/ApiContext";
import { AlertDialogModal } from "../components/core/AlertDialogModal";

const useStyle = makeStyles((theme) => ({
  leftBtn: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  rightBtn: {
    margin: "0px 16px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
}));

const GarageEditPage: React.FC = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const device = useDevice();
  const navigate = useNavigate();
  const classes = useStyle();
  const { id } = useParams();
  const { createGarage, updateGarage, getGarage } = useGarages();
  const [garage, setGarage] = useState<GarageEdit | undefined>();
  const [garageFetched, setGarageFetched] = useState<boolean>(false);
  const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);

  useEffect(() => {
    if (!garageFetched && id) {
      getGarage(id)
        .then((garage) =>
          setGarage({
            ...garage,
            email: garage?.thirdPartymainContact?.email,
            firstname: garage?.thirdPartymainContact?.firstname,
            lastname: garage?.thirdPartymainContact?.lastname,
            vat: garage?.tva_intra,
          }),
        )
        .finally(() => setGarageFetched(true));
    }
  }, [garageFetched, id, getGarage]);

  const handleSend = () => {
    let sendPromise;
    if (id) {
      sendPromise = updateGarage(garage!);
    } else {
      const newGarage: GarageEdit = {
        ...garage,
        language: i18next.language,
      };
      sendPromise = createGarage(newGarage);
    }
    return sendPromise
      .then(() => {
        navigate("/garages");
        const myAlert = alert.show("", {
          title: t("SaveGarageSuccess"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);
      })
      .catch((error: ApiError) => {
        let alertTitle = t("Error");
        let alertMessage = t("SaveGarageError");
        if (error.status === 403) {
          alertTitle = t("SaveGarageForbiddenTitle");
          alertMessage = t("SaveGarageForbiddenDesc");
        }
        const myAlert = alert.show(alertMessage, {
          title: alertTitle,
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  return (
    <>
      <Header
        focus={false}
        variant="primary"
        title={id ? t("EditGarageTitle") : t("NewGarageTitle")}
        titleIcon={id ? <Edit /> : <Add />}
        titleDesc=""
      >
        {device.isMobile ? (
          <HeaderActions>
            <CircleButton
              className={classes.leftBtn}
              onClick={() => setCancelDialogOpen(true)}
            >
              <CloseIcon />
            </CircleButton>
          </HeaderActions>
        ) : (
          <HeaderActions>
            <Button
              className={classes.leftBtn}
              variant="outlined"
              endIcon={<Cancel />}
              onClick={() => setCancelDialogOpen(true)}
            >
              {t("AbortEditGarage")}
            </Button>
          </HeaderActions>
        )}
      </Header>
      <Content centered>
        {(!id || garage) && (
          <GarageForm
            garage={garage}
            edit={!!id}
            onChange={(g) => {
              setGarage(g);
            }}
            onSend={() => handleSend()}
          />
        )}
      </Content>
      {isCancelDialogOpen && (
        <AlertDialogModal
          open={isCancelDialogOpen}
          icon={<Warning />}
          mainIcon={<Cancel />}
          title={t("ExitEditGarageTitle")}
          content={t("ExitEditGarageDesc")}
          confirmText={t("ExitEditGarageConfirm")}
          cancelText={t("ExitEditGarageCancel")}
          onClose={() => setCancelDialogOpen(false)}
          onConfirm={() => {
            setCancelDialogOpen(false);
            navigate("/garages");
          }}
        />
      )}
    </>
  );
};

export default GarageEditPage;
