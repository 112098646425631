import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  fullWidth: {
    width: "100%",
  },
  overview: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    padding: theme.spacing(2),
    minWidth: "20%",
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    "&:not(:last-of-type)": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  },
  subtotal: {
    color: theme.palette.primary.main,
  },
  subtotalValue: {
    marginLeft: theme.spacing(2),
  },
}));

const TableTotal = ({
  totalHt = 0,
  totalTva = 0,
  totalTtc = 0,
  fullWidth = false,
}) => {
  const classes = useStyle();
  return (
    <Box className={classes.container}>
      <Box
        className={`${classes.overview} ${fullWidth ? classes.fullWidth : ""}`}
      >
        <Box className={classes.row}>
          <Typography variant="subtitle2" className={classes.subtotal}>
            Total HT
          </Typography>
          <Typography variant="subtitle2" className={classes.subtotalValue}>
            {totalHt} €
          </Typography>
        </Box>
        <Box className={classes.row}>
          <Typography variant="subtitle2" className={classes.subtotal}>
            Total TVA
          </Typography>
          <Typography variant="subtitle2" className={classes.subtotalValue}>
            {totalTva} €
          </Typography>
        </Box>
        <Box className={classes.row}>
          <Typography variant="subtitle2" className={classes.subtotal}>
            Total TTC
          </Typography>
          <Typography variant="subtitle2" className={classes.subtotalValue}>
            {totalTtc} €
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TableTotal;
