import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  FormControl,
  TextField,
  FormHelperText,
  Button,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link, useNavigate } from "react-router-dom";
import { AlertCustomOptions, useAlert } from "react-alert";
import { useAuth } from "../hooks/useAuth";
import AuthFormContainer from "./AuthFormContainer";

const useStyle = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    width: "100%",
    margin: `${theme.spacing(1)}px 0`,
  },
  btn: {
    width: "100%",
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px 0`,
  },
  link: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
    textDecoration: "none",
    marginTop: 10,
  },
}));

const ForgetPasswordForm: React.FC = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const theme = useTheme();
  const alert = useAlert();
  const navigate = useNavigate();
  const { askResetPassword } = useAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const [email, setEmail] = useState<string>(queryParams.get("email") ?? "");

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleSendResetLink = async () => {
    try {
      await askResetPassword(email);
      const myAlert = alert.show(t("SuccessResetPasswordLinkSendDesc"), {
        title: t("SuccessResetPasswordLinkSend"),
        close: () => {
          alert.remove(myAlert);
        },
        type: "success",
      } as unknown as AlertCustomOptions);
      navigate("/login");
    } catch (err) {
      console.error(err);
      const myAlert = alert.show(t("ErrorResetPasswordLinkSendDesc"), {
        title: t("ErrorResetPasswordLinkSend"),
        close: () => {
          alert.remove(myAlert);
        },
        type: "error",
      } as unknown as AlertCustomOptions);
    }
  };

  return (
    <AuthFormContainer>
      <Typography variant="h4" className={classes.title}>
        {t("PasswordForgotten")}
      </Typography>

      <Typography
        variant="body1"
        style={{
          marginBottom: theme.spacing(2),
          color: theme.palette.text.primary,
        }}
      >
        {t("ResetPasswordInfo")}
      </Typography>

      <FormControl className={classes.inputContainer}>
        <TextField
          id="email"
          label={t("YourEmail")}
          variant="outlined"
          color="secondary"
          aria-describedby="email-helper"
          onChange={handleEmailChange}
          value={email}
        />
        <FormHelperText id="email-helper">
          {t("YourEmailHelperLogin")}
        </FormHelperText>
      </FormControl>

      <Button
        variant="contained"
        color="secondary"
        className={classes.btn}
        endIcon={<ArrowForwardIcon />}
        onClick={handleSendResetLink}
      >
        {t("ReceiveResetPasswordLink")}
      </Button>

      <Link to="/login" className={classes.link}>
        {t("Cancel")}
      </Link>
    </AuthFormContainer>
  );
};

export default ForgetPasswordForm;
