import { Theme, createTheme } from "@material-ui/core";
import { useConfiguration } from "./useConfiguration";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

export interface CustomThemeType {
  theme: Theme;
  menuTheme: Theme;
}

const useCustomTheme = (): CustomThemeType => {
  const configuration = useConfiguration();

  const theme = prepareThemeWithPalette(
    configuration.themeDeclination.indexOf("wcontent") !== -1
      ? configuration.lightTheme!
      : configuration.darkTheme!,
  );
  const menuTheme = prepareThemeWithPalette(
    configuration.themeDeclination.indexOf("wmenu") !== -1
      ? configuration.lightTheme!
      : configuration.darkTheme!,
  );

  return {
    theme,
    menuTheme,
  };
};

const prepareThemeWithPalette = (palette: PaletteOptions): Theme => {
  return createTheme({
    palette: palette,
    typography: {
      h4: {
        fontWeight: 500,
      },
      h6: {
        lineHeight: "28px",
      },
    },
  });
};

export default useCustomTheme;
