import React, { useState } from "react";
import {
  InputAdornment,
  IconButton,
  FormControl,
  TextField,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface CotroliaPasswordProps {
  className: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  error: boolean;
  helperText: string;
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  autoComplete?: string;
}

const CotroliaPassword = React.forwardRef<
  HTMLInputElement,
  CotroliaPasswordProps
>((props, ref) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    const value = show ? false : true;
    setShow(value);
  };

  return (
    <FormControl className={props.className} error={props.error}>
      <TextField
        label={props.label}
        type={show ? "text" : "password"}
        variant="outlined"
        color="secondary"
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
        error={props.error}
        helperText={props.helperText}
        inputRef={ref}
        autoComplete={props.autoComplete}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="default"
                aria-label="toggle password visibility"
                onClick={() => handleShow()}
              >
                {show ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onKeyUp={props.onKeyUp}
      />
    </FormControl>
  );
});
CotroliaPassword.displayName = "CotroliaPassword";

export default CotroliaPassword;
