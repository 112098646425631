import React from "react";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import {
  Box,
  Grid,
  GridDirection,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme) => ({
  toggleButtonGroup: {
    width: "100%",
    borderRadius: 5,
    height: 40,
    "& .MuiToggleButton-root": {
      flex: 1,
      backgroundColor: theme.palette.grey[50],
      border: "1px solid black",
      borderColor: theme.palette.divider,
      color: theme.palette.text.primary,
    },
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const FieldPartNumber: React.FC<{
  direction?: GridDirection;
  exampleSrc: string;
  txtInfo: string;
}> = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <Box>
      <Grid
        container
        direction={props.direction ?? "row"}
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
          boxSizing: "border-box",
        }}
        spacing={4}
      >
        <Grid
          container
          item
          xs={12}
          md={props.direction === "column" ? 12 : 6}
          spacing={2}
          direction="column"
        >
          <Grid item>
            <Typography variant="h5">{t("PartNumber")}</Typography>
          </Grid>
          <Grid item>
            <ToggleButtonGroup
              value={intervention.packaging.partNb.toString()}
              exclusive
              onChange={(_e, value: string) => {
                if (value !== null) {
                  setIntervention({
                    ...intervention,
                    packaging: {
                      ...intervention.packaging,
                      partNb: parseInt(value),
                    },
                  });
                }
              }}
              className={classes.toggleButtonGroup}
            >
              <ToggleButton value="1">1</ToggleButton>
              <ToggleButton value="2">2</ToggleButton>
              <ToggleButton value="3">3</ToggleButton>
              <ToggleButton value="4">4</ToggleButton>
              <ToggleButton value="5">5</ToggleButton>
              <ToggleButton value="6">6</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <Typography variant="body2">{props.txtInfo}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={props.direction === "column" ? 12 : 6}>
          <img src={props.exampleSrc} style={{ width: "100%" }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FieldPartNumber;
