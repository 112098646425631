import { InputLabel, InputLabelProps, useTheme } from "@material-ui/core";
import React from "react";

const CotroliaInputLabel: React.FC<InputLabelProps> = (props) => {
  const theme = useTheme();
  return (
    <InputLabel
      {...props}
      style={{
        color: props.error
          ? theme.palette.error.main
          : theme.palette.primary.main,
        ...props.style,
      }}
    >
      {props.children}
    </InputLabel>
  );
};

export default CotroliaInputLabel;
