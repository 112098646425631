import React from "react";
import CotroliaTextField from "../../../../../components/core/input/CotroliaTextField";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import InterFormControl from "../../InterFormControl";
import { FormHelperText } from "@material-ui/core";

const FieldChassis: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl error={!!props.error}>
      <CotroliaTextField
        id="frame"
        label={t("Frame")}
        inputProps={{ maxLength: 17 }}
        maxLength={5}
        value={intervention.car.frame}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => {
          setIntervention({
            ...intervention,
            car: { ...intervention.car, frame: e.target.value },
          });
          props.onChange && props.onChange();
        }}
        required
        rows={1}
        error={!!props.error}
      />
      {props.error && (
        <FormHelperText variant="outlined">{props.error}</FormHelperText>
      )}
    </InterFormControl>
  );
};

export default FieldChassis;
