import { Button, Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { AlertCustomOptions, useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import ContentTwoSided, {
  LeftSide,
  RightSide,
} from "../../../components/core/ContentTwoSided";
import CotroliaFormControl from "../../../components/core/input/CotroliaFormControl";
import CotroliaTextField from "../../../components/core/input/CotroliaTextField";
import { useAuth } from "../../auth/hooks/useAuth";
import { emptyError } from "../../../utils/model";
import { capitalize, validateEmail } from "../../../utils/validation";
import { useUsers } from "../../user/hooks/useUsers";

const useStyle = makeStyles((theme: Theme) => ({
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    height: "56px",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const ProfileMainContact: React.FC = () => {
  const { t } = useTranslation();
  const { user, setUser, askResetPassword } = useAuth();
  const { updateMe } = useUsers();
  const alert = useAlert();
  const classes = useStyle();

  const [editMode, setEditMode] = useState(false);
  const [lastname, setLastname] = useState(user!.lastname);
  const [lastnameError, setLasnameError] = useState(emptyError);
  const [firstname, setFirstname] = useState(user!.firstname);
  const [firstnameError, setFirstnameError] = useState(emptyError);
  const [email, setEmail] = useState(user!.email);
  const [emailError, setEmailError] = useState(emptyError);

  const isValid =
    lastname !== "" &&
    firstname !== "" &&
    email !== "" &&
    !lastnameError.value &&
    !firstnameError.value &&
    !emailError.value;

  const handleCancel = () => {
    setEditMode(false);
    setLastname(user!.lastname);
    setFirstname(user!.firstname);
    setEmail(user!.email);
    setLasnameError(emptyError);
    setFirstnameError(emptyError);
    setEmailError(emptyError);
  };

  const handleSave = () => {
    updateMe(lastname, firstname, email)
      .then(() => {
        const myAlert = alert.show("", {
          title: t("InformationModified"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);
        setEditMode(false);
        setUser({
          ...user!,
          lastname: lastname,
          firstname: firstname,
          email: email,
        });
      })
      .catch((err) => {
        console.error(err);
        handleCancel();
        const myAlert = alert.show(err.message, {
          title: t("InternalError"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  const handleChangePassword = () => {
    askResetPassword(email)
      .then(() => {
        const myAlert = alert.show("", {
          title: t("PasswordResetLinkSent"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);
      })
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("MailNotSent"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  return (
    <>
      <ContentTwoSided>
        <LeftSide>
          <CotroliaFormControl style={{ margin: 0 }}>
            <CotroliaTextField
              label={t("Lastname")}
              value={lastname}
              required={editMode}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
              ) => {
                const value = e.target.value.toUpperCase();
                if (value !== "") {
                  setLasnameError(emptyError);
                } else {
                  setLasnameError({
                    value: true,
                    msg: t("RequiredFieldLastname"),
                  });
                }
                setLastname(value);
              }}
              disabled={!editMode}
              error={lastnameError.value}
              helperText={lastnameError.msg}
            />
          </CotroliaFormControl>

          <CotroliaFormControl style={{ margin: 0 }}>
            <CotroliaTextField
              label={t("Firstname")}
              value={firstname}
              required={editMode}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
              ) => {
                const value = capitalize(e.target.value);
                if (value !== "") {
                  setFirstnameError(emptyError);
                } else {
                  setFirstnameError({
                    value: true,
                    msg: t("RequiredFieldFirstname"),
                  });
                }
                setFirstname(value);
              }}
              disabled={!editMode}
              error={firstnameError.value}
              helperText={firstnameError.msg}
            />
          </CotroliaFormControl>
        </LeftSide>
        <RightSide>
          <CotroliaFormControl style={{ margin: 0 }}>
            <CotroliaTextField
              label={t("Email")}
              value={email}
              required={editMode}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
              ) => {
                const value = e.target.value;
                if (value === "") {
                  setEmailError({
                    value: true,
                    msg: t("RequiredFieldEmail"),
                  });
                } else if (!validateEmail(value)) {
                  setEmailError({
                    value: true,
                    msg: t("InvalidEmail"),
                  });
                } else {
                  setEmailError(emptyError);
                }
                setEmail(value);
              }}
              disabled={!editMode}
              error={emailError.value}
              helperText={emailError.msg}
            />
          </CotroliaFormControl>

          <CotroliaFormControl style={{ margin: 0 }}>
            <Box className={classes.actionContainer}>
              {editMode ? (
                <>
                  <Button color="secondary" onClick={handleCancel}>
                    {t("Cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={!isValid}
                  >
                    {t("Save")}
                  </Button>
                </>
              ) : (
                <>
                  <Button color="secondary" onClick={handleChangePassword}>
                    {t("ChangePassword")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setEditMode(true)}
                  >
                    {t("Modify")}
                  </Button>
                </>
              )}
            </Box>
          </CotroliaFormControl>
        </RightSide>
      </ContentTwoSided>
    </>
  );
};

export default ProfileMainContact;
