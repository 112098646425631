import { Address } from "../../companies/types/CompaniesTypes";

export interface UserPermissions {
  myInterventions: boolean;
  createIntervention: boolean;
  interventionInvoice: boolean;
  networkInterventions: boolean;
  viewNetworkGarages: boolean;
  editNetworkGarage: boolean;
  createNetworkGarage: boolean;
  adminDistributors: boolean;
  invoices: boolean;
  statistics: boolean;
  profileDistributer: boolean;
  profileBackOffice: boolean;
  profileGarage: boolean;
  profileCentrale: boolean;
  deliveryNote: boolean;
}

export interface User {
  id?: string;
  email: string;
  firstname: string;
  individual: boolean;
  language: string;
  lastname: string;
  notificationNb: number;
  thirdparty: ThirdParty;
  permissions: UserPermissions;
}

export enum ContactType {
  Main = "SCMAIN",
  Tech = "SCTECH",
  Billing = "BILLING",
  DeliveryNote = "DELIVERYNOTE",
}

export interface Contact {
  id?: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  type?: ContactType;
  fk_user?: string;
}

export interface ThirdParty {
  id: string;
  name: string;
  address: Address;
  phone: string;
  siret: string;
  vat: string;
  draftInterNb: number;
  billedInterNb: number;
  ongoingInterNb: number;
  closedInterNb: number;
  contactList: Contact[];
  directPay: boolean;
  customerCode?: string;
  distributerCode?: string;
  shopCode?: string;
}
