import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { FormHelperText } from "@material-ui/core";
import CotroliaAutocomplete from "../../../../../components/core/input/CotroliaAutocomplete";
import { PartRef } from "../../../types/NewInterventionFormTypes";
import { useApi } from "../../../../../hooks/useApi";

const FieldReference: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { call } = useApi();
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();

  const searchReference = (searchValue: string) => {
    return call(
      `/supercotrolia/complementary-part?table=supercotrolia_part_complementary&searchValue=${searchValue}`,
    );
  };

  return (
    <InterFormControl error={!!props.error}>
      <CotroliaAutocomplete
        id="ref"
        label={t("PartRef")}
        value={intervention.part.ref ?? { id: -1, label: "" }}
        required
        error={!!props.error}
        freeSolo
        onChange={(newValue: PartRef | string) => {
          let formattedValue: PartRef;
          if (typeof newValue === "string") {
            formattedValue = { id: "", ref: newValue };
            if (intervention.part.ref?.ref === newValue) {
              formattedValue = intervention.part.ref;
            }
          } else {
            formattedValue = newValue;
          }
          setIntervention({
            ...intervention,
            part: {
              ...intervention.part,
              ref: formattedValue,
            },
          });
          if (formattedValue && !formattedValue.id) {
            // At free typing validation (freesolo), we try to find an existing ref and fill the id.
            searchReference(formattedValue.ref).then((res) => {
              const resKeys = Object.keys(res);
              if (
                resKeys.length === 1 &&
                res[resKeys[0]] === formattedValue.ref
              ) {
                setIntervention({
                  ...intervention,
                  part: {
                    ...intervention.part,
                    ref: {
                      id: resKeys[0],
                      ref: res[resKeys[0]],
                    },
                  },
                });
              }
            });
          }
          props.onChange && props.onChange();
        }}
        getOptions={(value: string) =>
          searchReference(value).then((res) => {
            const ret: PartRef[] = [];
            Object.keys(res).forEach((key) => {
              ret.push({ id: key, ref: res[key] });
            });
            return ret;
          })
        }
        getOptionLabel={(option: string | PartRef) => {
          return typeof option === "string" ? option : option?.ref ?? "";
        }}
      />
      <FormHelperText variant="outlined">
        {props.error ?? t("ReferenceInterHelper")}
      </FormHelperText>
    </InterFormControl>
  );
};

export default FieldReference;
