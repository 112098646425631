import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  container: {
    minWidth: 500,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      width: "100%",
    },
  },
  card: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  title: {
    color: theme.palette.primary.contrastText,
  },
  cardContent: {
    margin: `${theme.spacing(1)}px 0`,
  },
  cardActions: {
    margin: `${theme.spacing(1)}px 0`,
    "& > a": {
      textDecoration: "none",
    },
  },
  btn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

const AuthOtherAction: React.FC<{
  cardTitle: string;
  cardBtnLabel: string;
  cardRedirect: string;
}> = (props) => {
  const classes = useStyle();

  return (
    <Box className={classes.container}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h4" className={classes.title}>
            {props.cardTitle}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Link to={props.cardRedirect}>
            <Button variant="contained" className={classes.btn}>
              {props.cardBtnLabel}
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
};

export default AuthOtherAction;
