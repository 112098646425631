import React, { useState } from "react";
import { makeStyles, Typography, Button, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link, useNavigate } from "react-router-dom";
import { AlertCustomOptions, useAlert } from "react-alert";
import CotroliaPassword from "../../../components/core/input/CotroliaPassword";
import PasswordChecklist from "react-password-checklist";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { useAuth } from "../hooks/useAuth";
import AuthFormContainer from "./AuthFormContainer";
import { jwtDecode } from "jwt-decode";
import { ValidateAccountToken } from "../types/AuthTypes";

const useStyle = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    width: "100%",
    margin: `${theme.spacing(1)}px 0`,
  },
  btn: {
    width: "100%",
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px 0`,
  },
  link: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
    textDecoration: "none",
    marginTop: 10,
  },
  passwordValidation: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    margin: `0 0 ${theme.spacing(1)}px 0 !important`,
    padding: `${theme.spacing(1)}px !important`,
    fontFamily: theme.typography.fontFamily,
  },
}));

const ValidateAccountForm: React.FC = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const theme = useTheme();
  const alert = useAlert();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const [invalidToken, setInvalidToken] = useState<boolean>(!token);
  let decodedToken: ValidateAccountToken | undefined;
  if (!invalidToken) {
    try {
      decodedToken = token ? jwtDecode(token) : undefined;
    } catch (e) {
      setInvalidToken(true);
    }
  }
  const { validateAccount } = useAuth();

  const handleResetPassword = async () => {
    if (password === confirmPassword) {
      try {
        await validateAccount(token ?? "", password);
        const myAlert = alert.show(t("YouCanLoginNow"), {
          title: t("SuccessAccountValidation"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);
        navigate("/login");
      } catch (err) {
        console.error(err);
        const myAlert = alert.show("", {
          title: t("ErrorAccountValidation"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      }
    }
  };

  return (
    <AuthFormContainer>
      <Typography variant="h4" className={classes.title}>
        {t("ConfirmAccount")}
      </Typography>

      {invalidToken && (
        <Typography color="error">{t("ErrorAccountValidation")}</Typography>
      )}

      {!invalidToken && (
        <>
          <Typography
            variant="body1"
            style={{
              marginBottom: theme.spacing(2),
              color: theme.palette.text.primary,
            }}
          >
            {t("ConfirmAccountEmailUsed") + decodedToken?.email}
          </Typography>

          <CotroliaPassword
            className={classes.inputContainer}
            label={t("NewPassword")}
            value={password}
            onChange={setPassword}
            error={false}
            helperText={""}
            autoComplete="new-password"
          />

          <CotroliaPassword
            className={classes.inputContainer}
            label={t("ConfirmPassword")}
            value={confirmPassword}
            onChange={setConfirmPassword}
            error={false}
            helperText={""}
            autoComplete="new-password"
          />

          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital", "match"]}
            minLength={8}
            value={password}
            valueAgain={confirmPassword}
            onChange={(isValid) => {
              setValidPassword(isValid);
            }}
            messages={{
              minLength: t("PasswordRuleMinLength"),
              specialChar: t("PasswordRuleSpecialChar"),
              number: t("PasswordRuleNumber"),
              capital: t("PasswordRuleCapital"),
              match: t("PasswordRuleMatch"),
            }}
            iconComponents={{
              ValidIcon: (
                <CheckIcon style={{ color: theme.palette.success.main }} />
              ),
              InvalidIcon: (
                <ClearIcon style={{ color: theme.palette.error.main }} />
              ),
            }}
            className={classes.passwordValidation}
          />

          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            endIcon={<ArrowForwardIcon />}
            onClick={handleResetPassword}
            disabled={!validPassword}
          >
            {t("ValidateAccount")}
          </Button>

          <Link to="/login" className={classes.link}>
            {t("Cancel")}
          </Link>
        </>
      )}
    </AuthFormContainer>
  );
};

export default ValidateAccountForm;
