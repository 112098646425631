import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import Content from "../../../../components/core/layout/Content";
import Header from "../../../../components/core/Header";
import HeaderActions from "../../../../components/core/HeaderActions";
import MemoryIcon from "@material-ui/icons/Memory";
import ContentCard from "../../../../components/core/layout/ContentCard";
import NewInterventionStepSkeleton from "../../../newintervention/components/steps/NewInterventionStepSkeleton";
import StepDetail from "./StepDetail";
import StepChoice from "./StepChoice";
import AppConstant from "../../../../utils/appConstant";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth/hooks/useAuth";
import { useInterventions } from "../../hooks/useInterventions";
import { useDevice } from "../../../../hooks/useDevice";

const useStyle = makeStyles((theme) => ({
  titleRightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: theme.spacing(2),
  },
}));

const InterventionProposal = () => {
  const { t } = useTranslation();
  const MIN_STEP = 1;
  const MAX_STEP = 2;
  const STEP_DETAIL = MIN_STEP;
  const STEP_CHOICE = MAX_STEP;
  const steps = [
    {
      key: "ProposalStepDetail",
      label: t("ProposalStepDetail"),
    },
    { key: "ProposalStepChoice", label: t("ProposalStepChoice") },
  ];

  const { id } = useParams();
  const alert = useAlert();
  const device = useDevice();
  const classes = useStyle();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { getInterventionProposal, markPropalAsRead, markPropalAsRefused } =
    useInterventions();

  const [data, setData] = useState(null);
  const [choice, setChoice] = useState(AppConstant.proposalChoiceYes);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(MIN_STEP);

  const nextStep = () => {
    if (step <= MAX_STEP) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > MIN_STEP) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    getInterventionProposal(id)
      .then((res) => {
        // Verify if we are in the right step
        if (
          res.interStatus &&
          parseInt(res.interStatus) !== AppConstant.interStatusQuoteReceive
        ) {
          alert.show(t("InterPropalNotAvailableDesc"), {
            title: t("InterPropalNotAvailable"),
            close: () => {
              alert.remove(alert);
            },
            type: "error",
          });
          // Redirect to intervention view
          navigate("/intervention/" + id, { replace: true });
        }
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert.show(t("InterNotFoundDesc"), {
          title: t("InterNotFound"),
          close: () => {
            alert.remove(alert);
          },
          type: "error",
        });
      });
  }, [id, alert, t, navigate]);

  const handleSend = () => {
    if (choice === AppConstant.proposalChoiceYes) {
      markPropalAsRead(id)
        .then(() => {
          alert.show("", {
            title: t("PropalSigned"),
            close: () => {
              alert.remove(alert);
            },
            type: "success",
          });
          // [#170] Redirect to invoice for direct pay user
          if (user?.thirdparty?.directPay) {
            navigate("/intervention/" + id + "/invoice", { replace: true });
          } else {
            navigate("/intervention/" + id, { replace: true });
          }
        })
        .catch((err) => {
          console.error(err);
          alert.show(t("PropalNotSignedDesc"), {
            title: t("PropalNotSigned"),
            close: () => {
              alert.remove(alert);
            },
            type: "error",
          });
        });
    } else {
      markPropalAsRefused(id, choice === AppConstant.proposalChoiceReturn)
        .then(() => {
          alert.show("", {
            title: t("PropalRefused"),
            close: () => {
              alert.remove(alert);
            },
            type: "success",
          });
          navigate("/intervention/" + id, { replace: true });
        })
        .catch((err) => {
          console.error(err);
          alert.show(t("PropalNotRefusedDesc"), {
            title: t("PropalNotRefused"),
            close: () => {
              alert.remove(alert);
            },
            type: "error",
          });
        });
    }
  };

  const displayStep = () => {
    switch (step) {
      case STEP_DETAIL:
        return (
          <StepDetail
            data={data}
            nextStep={nextStep}
            steps={steps}
            activeStep={step - 1}
          />
        );
      case STEP_CHOICE:
        return (
          <StepChoice
            previousStep={prevStep}
            steps={steps}
            activeStep={step - 1}
            choice={choice}
            setChoice={setChoice}
            handleSend={handleSend}
            data={data}
          />
        );
      default:
        return <div>Step {step} not implemented</div>;
    }
  };

  return (
    <>
      <Header focus={true} variant="secondary">
        <HeaderActions>
          <Box display="flex" alignItems="center">
            <Box className={classes.titleRightContainer}>
              <Typography variant="h4">
                {data ? (
                  data.interRef + " " + t("Proposal")
                ) : (
                  <Skeleton width={200} />
                )}
              </Typography>
              <Typography variant="subtitle1">
                {data ? (
                  t("YourRef") +
                  " : " +
                  (data.interCustomerRef
                    ? data.interCustomerRef
                    : t("NoCustomerRef"))
                ) : (
                  <Skeleton width={250} />
                )}
              </Typography>
            </Box>
            {!device.isMobile && <MemoryIcon />}
          </Box>
        </HeaderActions>
      </Header>
      <Content>
        <ContentCard>
          {loading ? <NewInterventionStepSkeleton /> : displayStep()}
        </ContentCard>
      </Content>
    </>
  );
};

export default InterventionProposal;
