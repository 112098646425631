import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import CotroliaTextField from "../../../../../components/core/input/CotroliaTextField";
import { FormHelperText } from "@material-ui/core";

const FieldDescription: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl error={!!props.error}>
      <CotroliaTextField
        id="description"
        label={t("PartDescription")}
        value={intervention.part.description}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => {
          setIntervention({
            ...intervention,
            part: {
              ...intervention.part,
              description: e.target.value,
            },
          });
          props.onChange && props.onChange();
        }}
        required
        multiline
        rows={4}
        error={!!props.error}
      />
      <FormHelperText variant="outlined">
        {props.error ?? t("PartDescriptionHelper")}
      </FormHelperText>
    </InterFormControl>
  );
};

export default FieldDescription;
