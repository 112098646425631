import { Box, Button, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router";
import { useAuth } from "../../../auth/hooks/useAuth";
import { Build } from "@material-ui/icons";

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    margin: theme.spacing(2, 0),
    textAlign: "center",
  },
  margin: {
    margin: theme.spacing(1, 0),
  },
}));

const NoIntervention: React.FC<{ title: string; desc: string }> = (props) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleClick = () => {
    navigate("/intervention/new");
  };

  return (
    <Box className={classes.root}>
      <Build fontSize="large" />
      <Typography variant="h5" className={classes.margin}>
        {props.title}
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        className={classes.margin}
      >
        {props.desc}
      </Typography>
      {user?.permissions.createIntervention && (
        <Button
          variant="contained"
          color="primary"
          endIcon={<AddCircleOutlineIcon />}
          className={classes.margin}
          onClick={handleClick}
        >
          {t("NewIntervention")}
        </Button>
      )}
    </Box>
  );
};

export default NoIntervention;
