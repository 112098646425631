import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const FileUploadIcon: React.FC = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M14.5 2H6.5C5.4 2 4.51 2.9 4.51 4L4.5 20C4.5 21.1 5.39 22 6.49 22H18.5C19.6 22 20.5 21.1 20.5 20V8L14.5 2ZM18.5 20H6.5V4H13.5V9H18.5V20ZM8.5 15.01L9.91 16.42L11.5 14.84V19H13.5V14.84L15.09 16.43L16.5 15.01L12.51 11L8.5 15.01Z"
        fill="#276728"
      />
    </SvgIcon>
  );
};
