import React from "react";
import { useTranslation } from "react-i18next";
import AuthDivider from "../features/auth/components/AuthDivider";
import LoginForm from "../features/auth/components/LoginForm";
import { useConfiguration } from "../hooks/useConfiguration";
import AuthOtherAction from "../features/auth/components/AuthOtherAction";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const configuration = useConfiguration();

  return (
    <>
      <LoginForm />
      {configuration.canRegister && (
        <>
          <AuthDivider />
          <AuthOtherAction
            cardTitle={t("DontHaveAccount")}
            cardRedirect="/register"
            cardBtnLabel={t("Register")}
          />
        </>
      )}
    </>
  );
};

export default Login;
