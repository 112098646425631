import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import CotroliaTextField from "../../../../../components/core/input/CotroliaTextField";
import { FormHelperText } from "@material-ui/core";

const FieldPhone: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl error={!!props.error}>
      <CotroliaTextField
        id="phone"
        label={t("Phone")}
        value={intervention.shipping.phone}
        required
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => {
          setIntervention({
            ...intervention,
            shipping: { ...intervention.shipping, phone: e.target.value },
          });
          props.onChange && props.onChange();
        }}
        error={!!props.error}
      />
      <FormHelperText variant="outlined">
        {props.error ? props.error : t("PhoneHelper")}
      </FormHelperText>
    </InterFormControl>
  );
};

export default FieldPhone;
