// Model to represent an empty error
export const emptyError = {
  value: false,
  msg: "",
};

// Model to represent an address
export const emptyAddress = {
  street: "",
  zipCode: "",
  city: "",
  country: "",
  countryCode: "",
  fullAddress: "",
  unknown: false,
};
