import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import {
  Box,
  FormHelperText,
  Grid,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import CotroliaInputLabel from "../../../../../components/core/input/CotroliaInputLabel";
import CotroliaSwitch from "../../../../../components/core/input/CotroliaSwitch";
import UploadButton from "../../../../../components/core/UploadButton";

const useStyle = makeStyles((theme) => ({
  uploadContainer: {
    width: "100%",
  },
  mt: {
    marginTop: theme.spacing(2),
  },
}));

interface FieldPartDiagFilesPropsType {
  error?: string;
  onChange?: () => void;
}

const FieldPartDiagFiles: React.FC<FieldPartDiagFilesPropsType> = (props) => {
  const theme = useTheme();
  const classes = useStyle();
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  const [checked, setChecked] = useState<boolean>(
    intervention.part.diagFiles.length > 0,
  );
  return (
    <Grid
      container
      style={{
        width: "100%",
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(1.5),
        borderRadius: 10,
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          flexWrap: "nowrap",
        }}
      >
        <Grid
          container
          item
          direction="column"
          justifyContent="space-between"
          style={{ paddingRight: theme.spacing(1) }}
        >
          <CotroliaInputLabel
            id="diagfiles-label"
            shrink={false}
            variant="standard"
            style={{
              position: "relative",
              margin: 0,
              transform: "unset",
              lineHeight: "24px",
            }}
          >
            {t("DiagFilesLabel")}
          </CotroliaInputLabel>
          <FormHelperText
            id="diagfiles-helper"
            style={{
              margin: 0,
              lineHeight: "20px",
              fontSize: 12,
              fontWeight: 400,
              color: theme.palette.text.primary,
              opacity: 0.7,
            }}
          >
            {t("DiagFilesHelper")}
          </FormHelperText>
        </Grid>
        <Grid>
          <CotroliaSwitch
            id="diagfiles"
            aria-describedby="diagfiles-helper"
            checked={checked}
            defaultChecked={intervention.part.diagFiles.length > 0}
            onChange={(_e, value: boolean) => {
              setChecked(value);
              setIntervention({
                ...intervention,
                part: {
                  ...intervention.part,
                  diagFiles: value ? intervention.part.diagFiles : [],
                },
              });
              props.onChange && props.onChange();
            }}
          />
        </Grid>
      </Grid>
      {checked && (
        <Box className={classes.uploadContainer}>
          <Box className={classes.mt}>
            <UploadButton
              parentFiles={intervention.part.diagFiles}
              setParentFiles={(files: File[]) => {
                setIntervention({
                  ...intervention,
                  part: {
                    ...intervention.part,
                    diagFiles: files,
                  },
                });
              }}
              label={t("DiagFilesButtonLabel")}
              error={undefined}
              errorMsg={undefined}
              id={"upload-file-diagfiles"}
              nested
            />
          </Box>
          <FormHelperText
            variant="outlined"
            style={{ margin: 0, marginTop: theme.spacing(1) }}
          >
            {t("DiagFilesHelperFiletypes")}
          </FormHelperText>
        </Box>
      )}
    </Grid>
  );
};

export default FieldPartDiagFiles;
