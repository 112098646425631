import React from "react";

import { Box, makeStyles, MenuItem } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import CotroliaSelect from "../../../../components/core/input/CotroliaSelect";
import CotroliaFormControl from "../../../../components/core/input/CotroliaFormControl";
import CotroliaInputLabel from "../../../../components/core/input/CotroliaInputLabel";
import CotroliaInputSkeleton from "../../../../components/core/skeleton/CotroliaInputSkeleton";
import CotroliaTextareaSkeleton from "../../../../components/core/skeleton/CotroliaTextareaSkeleton";
import CotroliaTextField from "../../../../components/core/input/CotroliaTextField";
import { useConfiguration } from "../../../../hooks/useConfiguration";

const useStyle = makeStyles((theme) => ({
  descriptionContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& .MuiSkeleton-root, .MuiFormControl-root": {
      flexBasis: "33%",
      [theme.breakpoints.down("md")]: {
        flexBasis: "49%",
      },
      [theme.breakpoints.down("sm")]: {
        flexBasis: "100%",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "&:after": {
        content: "''",
        flexBasis: "33%",
      },
    },
  },
}));

/**
 * Content of the description section
 *
 * @param   {Object}  intervention     Intervention sent by the api
 * @returns                            Component content
 */
const InterventionDetailDescription = ({ intervention }) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const configuration = useConfiguration();

  return (
    <Box>
      <Box className={classes.descriptionContainer}>
        {/* Waranty */}
        {intervention ? (
          <CotroliaFormControl variant="filled">
            <CotroliaInputLabel id="waranty-label">
              {t("InterventionDetailWaranty")}
            </CotroliaInputLabel>
            <CotroliaSelect
              id="waranty"
              labelId="waranty-label"
              value={intervention.waranty ? parseInt(intervention.waranty) : 2}
              disabled
            >
              <MenuItem value={1}>{t("Yes")}</MenuItem>
              <MenuItem value={2}>{t("No")}</MenuItem>
            </CotroliaSelect>
          </CotroliaFormControl>
        ) : (
          <CotroliaInputSkeleton />
        )}

        {/* Manufacturer */}
        {configuration.sector !== "1" && (
          <>
            {intervention ? (
              <CotroliaFormControl>
                <CotroliaTextField
                  label={t("PartManufacturer")}
                  value={
                    intervention.manufacturer && intervention.manufacturer.label
                      ? intervention.manufacturer.label
                      : ""
                  }
                  disabled
                />
              </CotroliaFormControl>
            ) : (
              <CotroliaInputSkeleton />
            )}
          </>
        )}

        {/* Failure frequency */}
        {intervention ? (
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("PartFrequency")}
              value={intervention.frequency ? intervention.frequency : ""}
              disabled
            />
          </CotroliaFormControl>
        ) : (
          <CotroliaInputSkeleton />
        )}

        {/* Part type */}
        {intervention ? (
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("PartType")}
              value={
                intervention.partType && intervention.partType.label
                  ? intervention.partType.label
                  : ""
              }
              disabled
            />
          </CotroliaFormControl>
        ) : (
          <CotroliaInputSkeleton />
        )}

        {/* Ref */}
        {intervention ? (
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("PartRef")}
              value={
                intervention.partRef && intervention.partRef
                  ? intervention.partRef
                  : ""
              }
              disabled
            />
          </CotroliaFormControl>
        ) : (
          <CotroliaInputSkeleton />
        )}

        {/* Part Number */}
        {intervention ? (
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("PartNbr")}
              value={intervention.partNbr ? intervention.partNbr : ""}
              disabled
            />
          </CotroliaFormControl>
        ) : (
          <CotroliaInputSkeleton />
        )}
      </Box>

      {intervention ? (
        configuration.sector !== "1" && (
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("YourComment")}
              value={intervention.comment ? intervention.comment : ""}
              disabled
              multiline
              rows={4}
            />
          </CotroliaFormControl>
        )
      ) : (
        <CotroliaTextareaSkeleton />
      )}
    </Box>
  );
};

export default InterventionDetailDescription;
