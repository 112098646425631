import React from "react";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useDevice } from "../../../../../hooks/useDevice";

const useStyle = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
  },
  inputBackground: {
    width: "100%",
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
    "& > *": {
      padding: theme.spacing(1.5),
    },
  },
}));

const FieldClosingDays: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const device = useDevice();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <Box
      style={{
        height: device.isMobile ? "auto" : 79,
      }}
    >
      <FormLabel className={classes.label}>
        <Typography variant="body2">{t("ClosingDays")}</Typography>
      </FormLabel>
      <FormGroup style={{ flexDirection: "row" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={intervention.shipping.closingDates.monday}
              onChange={() => {
                const newValue = {
                  ...intervention.shipping.closingDates,
                };
                newValue.monday = !newValue.monday;
                setIntervention({
                  ...intervention,
                  shipping: {
                    ...intervention.shipping,
                    closingDates: newValue,
                  },
                });
              }}
              name="monday"
              color="primary"
            />
          }
          label={t("Monday")}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={intervention.shipping.closingDates.saturday}
              onChange={() => {
                const newValue = {
                  ...intervention.shipping.closingDates,
                };
                newValue.saturday = !newValue.saturday;
                setIntervention({
                  ...intervention,
                  shipping: {
                    ...intervention.shipping,
                    closingDates: newValue,
                  },
                });
              }}
              name="saturday"
              color="primary"
            />
          }
          label={t("Saturday")}
        />
      </FormGroup>
    </Box>
  );
};

export default FieldClosingDays;
