import React from "react";
import FieldMethod from "../fields/shipping/FieldMethod";
import { useStepValidator } from "../../hooks/useStepValidator";
import { RepturnIcon } from "../../../../components/icons/RepturnIcon";

const StepShipping: React.FC = () => {
  useStepValidator(() => true);
  return (
    <FieldMethod
      customPickingChoice={{
        icon: <RepturnIcon />,
      }}
    />
  );
};

export default StepShipping;
