import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import image from "../../assets/img/maintenance.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    textAlign: "center",
  },
  img: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const UnderMaintenance = ({ title }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <img src={image} alt="Under maintenance" className={classes.img} />
      <Typography variant="h2">
        {t("UnderMaintenanceTitle", { title: title.toLowerCase() })}
      </Typography>
      <Typography variant="subtitle1">{t("UnderMaintenanceDesc")}</Typography>
    </div>
  );
};

export default UnderMaintenance;
