import React from "react";
import { useStepValidator } from "../../../hooks/useStepValidator";
import FieldMethod from "../../fields/shipping/FieldMethod";
import { CotroliaIcon } from "../../../../../components/icons/CotroliaIcon";
import { useTranslation } from "react-i18next";

const StepShippingCotrolia: React.FC = () => {
  const { t } = useTranslation();
  useStepValidator(() => true);
  return (
    <FieldMethod
      customPickingChoice={{
        icon: <CotroliaIcon />,
        title: t("PickingMethodCotrolia"),
        info1: t("PickingMethodInfo1Cotrolia"),
      }}
      customDeskChoice={{
        info1: t("DeskMethodInfo1Cotrolia"),
      }}
    />
  );
};

export default StepShippingCotrolia;
