import React from "react";
import Header from "../components/core/Header";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { useTranslation } from "react-i18next";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import CircleButton from "../components/core/CircleButton";
import { Link } from "react-router-dom";
import NotificationDashboardList from "../components/core/NotificationDashboardList";
import Content from "../components/core/layout/Content";
import DashboardInterventionList from "../features/dashboard/components/DashboardInterventionList";
import { useConfiguration } from "../hooks/useConfiguration";
import CotroliaNewsDashboardList from "../components/core/CotroliaNewsDashboardList";
import { useDevice } from "../hooks/useDevice";
import { useAuth } from "../features/auth/hooks/useAuth";

const Dashboard = () => {
  const { t } = useTranslation();
  const configuration = useConfiguration();
  const device = useDevice();
  const { user } = useAuth();

  return (
    <>
      <Header
        focus={false}
        title={t("Dashboard")}
        titleIcon={<DashboardIcon />}
        titleDesc=""
      >
        {user.permissions.createIntervention && (
          <>
            {device.isMobile ? (
              <Link to="/intervention/new" style={{ textDecoration: "none" }}>
                <CircleButton color="primary" variant="contained">
                  <AddCircleOutline />
                </CircleButton>
              </Link>
            ) : (
              <Link to="/intervention/new" style={{ textDecoration: "none" }}>
                <Button
                  color="primary"
                  variant="contained"
                  endIcon={<AddCircleOutline />}
                >
                  {t("NewIntervention")}
                </Button>
              </Link>
            )}
          </>
        )}
      </Header>
      <Content>
        <NotificationDashboardList />
        <DashboardInterventionList />
        {configuration.sector === "1" && <CotroliaNewsDashboardList />}
      </Content>
    </>
  );
};

export default Dashboard;
