import React, { useState } from "react";
import { Switch, SwitchProps, makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: 150,
      height: 40,
      padding: 0,
      borderRadius: 10,
    },
    switchBase: {
      padding: 0,
      color: theme.palette.primary.main,
      "&$checked": {
        transform: "translateX(75px)",
        color: theme.palette.primary.main,
        "& + $track": {
          backgroundColor: theme.palette.grey[100],
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: theme.palette.primary.main,
        opacity: 0.9,
      },
    },
    thumb: {
      width: 75,
      height: 40,
      borderRadius: 0,
    },
    track: {
      width: 150,
      backgroundColor: theme.palette.grey[100],
      opacity: 1,
    },
    checked: {},
    focusVisible: {},
    label: {
      position: "absolute",
      top: 0,
      height: "100%",
      width: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "background-color 300ms",
      pointerEvents: "none",
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      fontWeight: 700,
      textTransform: "uppercase",
    },
  };
});

const CotroliaSwitch: React.FC<SwitchProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [checked, setChecked] = useState<boolean>(
    props.defaultChecked ?? false,
  );
  return (
    <>
      <div style={{ position: "relative" }}>
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...props}
          onChange={(_event, checked) => {
            setChecked(checked);
            props.onChange && props.onChange(_event, checked);
          }}
        />
        <div
          className={classes.label}
          style={{
            left: 0,
            color: checked
              ? theme.palette.text.primary
              : theme.palette.common.white,
          }}
        >
          non
        </div>
        <div
          className={classes.label}
          style={{
            right: 0,
            color: checked
              ? theme.palette.common.white
              : theme.palette.text.primary,
          }}
        >
          oui
        </div>
      </div>
    </>
  );
};

export default CotroliaSwitch;
