import React from "react";

import { Box, makeStyles, Typography, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CotroliaStepperActions from "../../../../components/core/stepper/CotroliaStepperActions";

const NewInterventionStepSkeleton = () => {
  const theme = useTheme();

  const classes = makeStyles(() => ({
    root: {
      width: "100%",
      paddingTop: theme.spacing(4),
    },
    mt: {
      marginTop: theme.spacing(2),
    },
  }))();

  return (
    <div className={classes.root}>
      <Typography
        variant="h4"
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <Skeleton variant="text" style={{ width: "50%" }} />
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <Skeleton variant="text" style={{ width: "40%" }} />
      </Typography>

      <Box className={classes.mt} style={{ width: "100%" }}>
        <Skeleton variant="rect" style={{ height: "70px" }} />
      </Box>

      <Box className={classes.mt} style={{ width: "100%" }}>
        <Skeleton variant="rect" style={{ height: "400px" }} />
      </Box>

      <CotroliaStepperActions>
        <Skeleton variant="rect" height={36} width={100} />
        <Skeleton variant="rect" height={36} width={100} />
      </CotroliaStepperActions>
    </div>
  );
};

export default NewInterventionStepSkeleton;
