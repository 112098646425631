import React from "react";

import {
  Button,
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CotroliaStepper from "../../../../components/core/stepper/CotroliaStepper";
import CotroliaStepperActions from "../../../../components/core/stepper/CotroliaStepperActions";
import TableTotal from "../../../../components/core/TableTotal";

const useStyle = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
  tableContainer: {
    overflowX: "auto",
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px ${theme.spacing(
      0,
    )}px ${theme.spacing(1)}px`,
    "& .MuiTableCell-head": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableRow-root:not(.MuiTableRow-head):last-of-type .MuiTableCell-root":
      {
        borderBottom: "none",
      },
  },
}));

const StepDetail = ({ data, nextStep, steps, activeStep }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("ProposalDetail")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("ProposalDetailDesc")}
      </Typography>
      <CotroliaStepper
        steps={steps}
        activeStep={activeStep}
        variant="secondary"
      />

      {/* Lines table */}
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t("ProductCode")}</TableCell>
              <TableCell>{t("ProductName")}</TableCell>
              <TableCell>{t("TotalHT")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.lines.map((line, idx) => {
              return (
                <TableRow key={idx}>
                  {line.type === "freeline" ? (
                    <TableCell colSpan={3}>
                      <div
                        dangerouslySetInnerHTML={{ __html: line.title }}
                      ></div>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell>{line.code}</TableCell>
                      <TableCell>{line.title}</TableCell>
                      <TableCell>{line.total_ht}</TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box m={2} width="100%">
        <TableTotal
          totalHt={data.total_ht || 0}
          totalTva={data.total_tva || 0}
          totalTtc={data.total_ttc || 0}
        />
      </Box>

      <CotroliaStepperActions>
        <Button
          startIcon={<ArrowBackIcon />}
          color="secondary"
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("Previous")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            nextStep();
          }}
          disabled={false}
        >
          {t("Next")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default StepDetail;
