import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { FormHelperText, Typography, useTheme } from "@material-ui/core";
import CotroliaTextField from "../../../../../components/core/input/CotroliaTextField";

const FieldClientRef: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl>
      <Typography
        variant="subtitle1"
        style={{
          marginBottom: theme.spacing(1.5),
          color: theme.palette.primary.main,
        }}
      >
        {t("PartClientRefTitle")}
      </Typography>
      <CotroliaTextField
        id="clientRef"
        label={t("PartClientRef")}
        value={intervention.part.clientRef}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => {
          setIntervention({
            ...intervention,
            part: {
              ...intervention.part,
              clientRef: e.target.value,
            },
          });
        }}
      />
      <FormHelperText variant="outlined">
        {t("PartClientRefHelper")}
      </FormHelperText>
    </InterFormControl>
  );
};

export default FieldClientRef;
