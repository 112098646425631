import { TextField, makeStyles } from "@material-ui/core";

const CotroliaTextField = (props) => {
  const classes = makeStyles((theme) => ({
    textfield: {
      // Label Color
      "& .MuiInputLabel-filled": {
        color: theme.palette.primary.main,
        "&.Mui-error": {
          color: theme.palette.error.main,
        },
        "&.Mui-disabled": {
          color: props.labelColorDisabled ?? theme.palette.primary.main,
        },
      },

      // Input form
      "& .MuiFilledInput-root": {
        borderRadius: theme.spacing(1) + 2,
        backgroundColor: theme.palette.grey[props.nested ? 50 * 2 : 50],
        "&::before": {
          borderBottom: "none",
        },
        "&:hover:before": {
          borderBottom: "none",
        },
        // Border bottom of the input
        "&.MuiFilledInput-underline": {
          "&:after": {
            width: `calc(100% - ${(theme.spacing(1) + 2) * 2}px)`, // We substract border-radius of each corner
            left: theme.spacing(1) + 2, // We center the line
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          },
          "&.Mui-error:after": {
            borderColor: theme.palette.error.main,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.grey[props.nested ? 50 * 2 : 50],
            color: theme.palette.text.primary,

            "& .MuiSvgIcon-root": {
              color: props.labelColorDisabled ?? theme.palette.primary.main,
            },
          },
        },
      },

      // Border radius on the shadow on focus
      "& .MuiSelect-select:focus": {
        borderRadius: theme.spacing(1) + 2,
      },
    },
  }))();
  return (
    <TextField
      className={`${classes.textfield} ${props.className}`}
      variant="filled"
      {...props}
    >
      {props.children}
    </TextField>
  );
};

export default CotroliaTextField;
