import React, { useState } from "react";
import { AlertCustomOptions, useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { useSentry } from "../../../hooks/useSentry";
import { useApi } from "../../../hooks/useApi";
import { User, UserPermissions } from "../../user/types/userTypes";

interface AuthContextType {
  user?: User;
  wayf: (email: string) => Promise<boolean>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  fetchConnectedUser: () => Promise<void>;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  askResetPassword: (email: string) => Promise<void>;
  resetPassword: (token: string, password: string) => Promise<void>;
  validateAccount: (token: string, password: string) => Promise<void>;
  updatePermissions: (permisssions: UserPermissions) => void;
}

export const AuthContext = React.createContext<AuthContextType>(
  {} as AuthContextType,
);

export const AuthProvider: React.FC = (props) => {
  const [user, setUser] = useState<User>();
  const { call } = useApi();
  const { t, i18n } = useTranslation();
  const { setupSentryUser } = useSentry();
  const navigate = useNavigate();
  const alert = useAlert();
  const configuration = useConfiguration();

  const fetchConnectedUser = async () => {
    await call("/supercotrolia/me")
      .then(async (res: User) => {
        setUser(res);
        setupSentryUser(user);
        const userLang = res.language;
        if (userLang && i18n.language !== userLang) {
          await i18n.changeLanguage(userLang);
          await configuration.refresh(); // Refresh configuration in the user language
        }
        await configuration.refreshInterConfig();
      })
      .catch((reason) => {
        if (reason.status === 401) {
          console.log("Receive 401 on /me : please ensure user is logged");
        } else {
          throw reason;
        }
      });
  };

  const wayf = async (email: string): Promise<boolean> => {
    return call("/supercotrolia/wayf", {
      data: {
        login: email,
      },
    })
      .then((res) => res)
      .catch((err) => {
        console.log("Error on wayf request : " + JSON.stringify(err));
        return true;
      });
  };

  const login = async (email: string, password: string) => {
    return call("/supercotrolia/login", {
      data: {
        login: email,
        password: password,
      },
    })
      .then(() => fetchConnectedUser())
      .catch((err) => {
        let errMsg = t("InternalError");
        if (err.status && err.status === 403) {
          errMsg = t("WrongLoginOrPassword");
        }
        const myAlert = alert.show(errMsg, {
          title: t("Error"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  const logout = () => {
    call("/logout", { method: "POST" }).finally(() => {
      setUser(undefined);
      setupSentryUser(undefined);
      navigate("/login", { replace: true });
    });
  };

  const askResetPassword = (email: string) =>
    call("/supercotrolia/reset-password-link?email=" + email);

  const resetPassword = (token: string, password: string) =>
    call("/supercotrolia/reset-password", {
      data: {
        token: token,
        password: password,
      },
    });

  const validateAccount = (token: string, password: string) =>
    call("/supercotrolia/account_validation", {
      data: {
        token: token,
        password: password,
      },
    });

  const updatePermissions = (permisssions: UserPermissions) => {
    setUser({ ...user!, permissions: permisssions });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        wayf,
        login,
        logout,
        fetchConnectedUser,
        setUser,
        askResetPassword,
        resetPassword,
        validateAccount,
        updatePermissions, // Temporary method to facilitate roles testing
      }}
      {...props}
    >
      <Outlet />
    </AuthContext.Provider>
  );
};
