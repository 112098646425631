import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<unknown, string> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AlertDialogModal: React.FC<{
  open: boolean;
  title: string;
  content: string;
  icon?: React.ReactNode;
  mainIcon?: React.ReactNode;
  secondaryIcon?: React.ReactNode;
  confirmText: string;
  cancelText: string;
  onClose: () => void;
  onConfirm: () => void;
}> = (props) => {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => props.onClose()}
    >
      {" "}
      <DialogTitle>
        <Box display="flex" alignItems="center">
          {props.icon && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "5px",
              }}
            >
              {props.icon}
            </div>
          )}
          {props.title}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          endIcon={props.secondaryIcon}
          onClick={() => props.onClose()}
        >
          {props.cancelText}
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={props.mainIcon}
          onClick={() => props.onConfirm()}
        >
          {props.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
