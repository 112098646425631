import React from "react";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { ShippingMethod } from "../../../types/NewInterventionFormTypes";
import { Box, Card, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useConfiguration } from "../../../../../hooks/useConfiguration";

const FieldEntityAddress: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { interventionConfig } = useConfiguration();
  const { intervention } = useNewInterventionForm();
  return (
    <Box>
      {[ShippingMethod.Personal, ShippingMethod.Desk].includes(
        intervention.shipping.method,
      ) && (
        <>
          <Typography
            variant="subtitle1"
            style={{
              marginBottom: theme.spacing(1.5),
              color: theme.palette.primary.main,
            }}
          >
            {intervention.shipping.method === ShippingMethod.Personal &&
              t("EntityAddressTitlePersonal")}
            {intervention.shipping.method === ShippingMethod.Desk &&
              t("EntityAddressTitleDesk")}
          </Typography>
          <Card
            elevation={0}
            style={{
              border: `1px dashed ${theme.palette.grey[300]}`,
              padding: theme.spacing(1.5),
              borderRadius: 10,
            }}
          >
            {interventionConfig.entityAddress.split(",").map((s, index) => (
              <Typography
                key={index}
                variant={index === 0 ? "h6" : "body1"}
                style={{
                  fontWeight: index === 0 ? 500 : 700,
                  marginTop: index === 0 ? 0 : theme.spacing(1),
                }}
              >
                {s}
              </Typography>
            ))}

            {intervention.shipping.method === ShippingMethod.Desk && (
              <Typography
                variant="body1"
                style={{
                  color: theme.palette.text.secondary,
                  marginTop: theme.spacing(1.5),
                }}
              >
                {t("EntityAddressTitleDeskHours")}
              </Typography>
            )}
          </Card>
        </>
      )}
    </Box>
  );
};

export default FieldEntityAddress;
