const AppConstant = {
  drawerWidth: 304,
  drawerWidthClose: 89,
  notificationCardWidth: 488,
  notificationCardWidthSm: 297,
  cornerZIndex: 1200,
  interventionCardWidthList: 314,
  interStatusRegistered: 0,
  interStatusTransmited: 1,
  interStatusToSend: 2,
  interStatusSended: 3,
  interStatusDeclined: 4,
  interStatusReceive: 5,
  interStatusQuoteReceive: 6,
  interStatusQuoteAccepted: 7,
  interStatusPaymentPending: 8,
  interStatusRepair: 9,
  interStatusOnTheWay: 10,
  interStatusFinalized: 11,
  interStatusAlert: 12,
  warrantyYes: 1,
  warrantyNo: 2,
  proposalChoiceYes: 1,
  proposalChoiceNo: 2,
  proposalChoiceReturn: 3,
  invoiceChoiceCB: 1,
  invoiceChoiceBankTransfer: 2,
  invoiceChoiceCheque: 3,
  shippingMethodPicking: 1,
  shippingMethodPersonal: 2,
  shippingMethodDesk: 3,
  exportMethodCarrier: 1,
  exportMethodDesk: 2,
  proposalTypeRepair: 1,
  proposalTypeCannotRepair: 2,
  proposalTypeRecondi: 3,
  proposalTypeRecondiNotObserved: 4,
  proposalTypeVerification: 5,
  defaultLanguage: "fr_FR",
};

export default AppConstant;
