import React from "react";
import { useTranslation } from "react-i18next";
import AuthDivider from "../features/auth/components/AuthDivider";
import AuthOtherAction from "../features/auth/components/AuthOtherAction";
import RegisterForm from "../features/auth/components/RegisterForm";

const Register: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <RegisterForm />
      <AuthDivider />
      <AuthOtherAction
        cardTitle={t("AlreadyHaveAccount")}
        cardRedirect="/login"
        cardBtnLabel={t("Login")}
      />
    </>
  );
};

export default Register;
