import React, { useState } from "react";
import { Contact, ContactType } from "../../user/types/userTypes";
import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import SelectOrAddContact from "../../../components/core/SelectOrAddContact";
import AddContactForm from "../../../components/core/AddContactForm";
import { AlertCustomOptions, useAlert } from "react-alert";
import { useContacts } from "../../user/hooks/useContacts";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth/hooks/useAuth";
import _ from "lodash";
import {
  Delete,
  Edit,
  LinkOff,
  Person,
  PersonAdd,
  PersonAddDisabled,
} from "@material-ui/icons";
import { Popup, PopupCard } from "../../../components/core/Popup";

const useStyle = makeStyles((theme) => ({
  listEntryContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1) + 2,
    position: "relative",
  },
  entryContainer: {
    display: "flex",
    alignItems: "center",
    padding: `0 ${theme.spacing(2)}px`,
  },
  entryLabel: {
    flexGrow: 1,
  },
  entryBtn: {
    color: theme.palette.text.secondary,
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));
const ProfileContactList: React.FC<{
  type: ContactType;
  multiple?: boolean;
  enableCreateAccount?: boolean;
}> = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { user, setUser } = useAuth();
  const alert = useAlert();
  const { createContact, addContactRole } = useContacts();

  const [addMode, setAddMode] = useState<boolean>(false);
  const contactList = _.uniqBy(
    user!.thirdparty.contactList,
    (c) => c.id,
  ).filter((contact) => contact.type === props.type);

  const handleAdd = (id: string) => {
    addContactRole(id, props.type)
      .then(() => {
        const myAlert = alert.show("", {
          title: t("ContactAdded"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);

        // Update local user informations
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === id ? { ...c, type: props.type } : c,
            ),
          },
        });
      })
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("ContactNotAdded"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  const handleCreate = (contact: Contact) => {
    createContact({ ...contact, type: props.type })
      .then((res) => {
        const myAlert = alert.show("", {
          title: t("ContactAdded"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);

        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: [
              ...user!.thirdparty.contactList,
              { ...contact, id: res.id, type: props.type },
            ],
          },
        });
        setAddMode(false);
      })
      .catch((err) => {
        console.error(err);

        if (err.status === 403) {
          const myAlert = alert.show(t("TryAgainLater"), {
            title: t("ContactNotAdded403"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        } else {
          const myAlert = alert.show(t("TryAgainLater"), {
            title: t("ContactNotAdded"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        }
      });
  };

  return (
    <>
      <Box className={classes.margin}>
        {(contactList.length === 0 || props.multiple) && (
          <SelectOrAddContact
            required={false}
            selected=""
            onSelect={handleAdd}
            onClick={() => setAddMode(true)}
            excludeList={contactList as never[]}
          />
        )}

        {addMode && (
          <>
            {props.multiple && <Divider className={classes.divider} />}
            <AddContactForm
              handleAdd={(
                lastname: string,
                firstname: string,
                email: string,
                phone: string,
              ) => handleCreate({ lastname, firstname, email, phone })}
              handleCancel={() => setAddMode(false)}
            />
          </>
        )}
      </Box>
      {props.multiple && <Divider />}
      <Box className={classes.margin}>
        <Box className={classes.listEntryContainer}>
          {contactList.map((contact, index) => (
            <ContactEntry
              key={index}
              contact={contact}
              enableCreateAccount={props.enableCreateAccount}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

const ContactEntry: React.FC<{
  contact: Contact;
  enableCreateAccount?: boolean;
}> = (props) => {
  const classes = useStyle();
  const alert = useAlert();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const { user, setUser } = useAuth();
  const {
    updateContact,
    deleteContact,
    deleteContactRole,
    createContactAccount,
    deleteContactAccount,
  } = useContacts();
  const { i18n } = useTranslation();

  const handleDelete = () => {
    deleteContactRole(props.contact.id!, props.contact.type!)
      .then(() =>
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === props.contact.id ? { ...c, type: undefined } : c,
            ),
          },
        }),
      )
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("ContactNotDeleted"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  const handleEdit = (contact: Contact) => {
    updateContact(contact)
      .then(() =>
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === props.contact.id ? contact : c,
            ),
          },
        }),
      )
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("ContactNotModified"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
    setEditMode(false);
  };

  const handleCreateAccount = () => {
    createContactAccount(props.contact.id!, i18n.language)
      .then((res) => {
        const myAlert = alert.show(
          `${t("MailSent")} ${props.contact.email} ${t("ToCreatePassword")}`,
          {
            title: t("AccountCreated"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "success",
          } as unknown as AlertCustomOptions,
        );

        // Update local user informations
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === props.contact.id ? { ...c, fk_user: res.id } : c,
            ),
          },
        });
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          const myAlert = alert.show(t("RegisterErrorEmailAlreadyExists"), {
            title: t("AccountNotCreated"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        } else {
          const myAlert = alert.show(t("TryAgainLater"), {
            title: t("AccountNotCreated"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        }
      });
  };

  const handleDeleteAccount = () => {
    deleteContactAccount(props.contact.fk_user!)
      .then(() => {
        const myAlert = alert.show("", {
          title: t("AccountDeleted"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);

        // Update local user informations
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === props.contact.id ? { ...c, fk_user: undefined } : c,
            ),
          },
        });
      })
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("AccountNotDeleted"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  const handlePermanentDelete = () => {
    deleteContact(props.contact.id!)
      .then(() => {
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.filter(
              (c) => c.id !== props.contact.id,
            ),
          },
        });
      })
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("ContactNotDeleted"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  return (
    <>
      <Box className={classes.entryContainer}>
        <Person />
        <Typography variant="body1" className={classes.entryLabel}>
          {props.contact.lastname + " " + props.contact.firstname}
        </Typography>
        {props.enableCreateAccount && (
          <>
            {!props.contact.fk_user ? (
              <Tooltip title={t("CreateAccount")}>
                <IconButton
                  className={classes.entryBtn}
                  onClick={handleCreateAccount}
                >
                  <PersonAdd />
                </IconButton>
              </Tooltip>
            ) : user!.email === props.contact.email ? (
              <Tooltip title={t("MainAccountNotDeletable")}>
                <span>
                  <IconButton className={classes.entryBtn} disabled>
                    <PersonAddDisabled />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={t("DeleteAccount")}>
                <IconButton
                  className={classes.entryBtn}
                  onClick={handleDeleteAccount}
                >
                  <PersonAddDisabled />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
        <Tooltip title={t("EditContact")}>
          <IconButton
            className={classes.entryBtn}
            onClick={() => setEditMode(!editMode)}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("UnlinkContact")}>
          <IconButton className={classes.entryBtn} onClick={handleDelete}>
            <LinkOff />
          </IconButton>
        </Tooltip>
      </Box>
      {editMode && (
        <Popup>
          <PopupCard
            title={t("EditContact")}
            description={
              t("EditContactDesc") +
              " : " +
              props.contact.lastname +
              " " +
              props.contact.firstname +
              " <" +
              props.contact.email +
              ">"
            }
          >
            <AddContactForm
              lastname={props.contact.lastname}
              firstname={props.contact.firstname}
              email={props.contact.email}
              phone={props.contact.phone}
              addBtnLabel={t("Modify")}
              handleAdd={(
                lastname: string,
                firstname: string,
                email: string,
                phone: string,
              ) =>
                handleEdit({
                  ...props.contact,
                  lastname,
                  firstname,
                  email,
                  phone,
                })
              }
              handleCancel={() => setEditMode(false)}
            />
          </PopupCard>
          {user!.email !== props.contact.email && (
            <PopupCard
              title={t("DeleteContact")}
              description={
                t("DeleteContactDesc") +
                " : " +
                props.contact.lastname +
                " " +
                props.contact.firstname +
                " <" +
                props.contact.email +
                ">"
              }
              variant="outlined"
              color="secondary"
              action={handlePermanentDelete}
              actionLabel={t("Delete")}
              actionIcon={<Delete />}
            ></PopupCard>
          )}
        </Popup>
      )}
    </>
  );
};

export default ProfileContactList;
