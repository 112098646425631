import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import AppConstant from "../../utils/appConstant";
import { useEffect } from "react";
import { useAuth } from "../../features/auth/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../features/notifications/hooks/useNotifications";

const useStyle = makeStyles((theme) => ({
  card: {
    width: AppConstant.notificationCardWidth,
    height: "100%",
    "&.fullWidth": {
      width: "100%",
    },
    "& .MuiCardActions-root": {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      width: AppConstant.notificationCardWidthSm,
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& button": {
      // Accept button
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "& button": {
      // Accept button
      backgroundColor: theme.palette.secondary.contrastText,
      color: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    "& button": {
      // Accept button
      backgroundColor: theme.palette.info.contrastText,
      color: theme.palette.info.main,
      "&:hover": {
        backgroundColor: theme.palette.info.dark,
        color: theme.palette.info.contrastText,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
      },
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    "& button": {
      // Accept button
      backgroundColor: theme.palette.warning.contrastText,
      color: theme.palette.warning.main,
      "&:hover": {
        backgroundColor: theme.palette.warning.dark,
        color: theme.palette.warning.contrastText,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.warning.contrastText,
        backgroundColor: theme.palette.warning.main,
      },
    },
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    "& button": {
      // Accept button
      backgroundColor: theme.palette.success.contrastText,
      color: theme.palette.success.main,
      "&:hover": {
        backgroundColor: theme.palette.success.dark,
        color: theme.palette.success.contrastText,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
      },
    },
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "& button": {
      // Accept button
      backgroundColor: theme.palette.error.contrastText,
      color: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  whitePrimary: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    "& button": {
      // Accept button
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  whiteSecondary: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    "& button": {
      // Accept button
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  whiteInfo: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    "& button": {
      // Accept button
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.info.dark,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.info.main,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  whiteWarning: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    "& button": {
      // Accept button
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.warning.dark,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  whiteError: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    "& button": {
      // Accept button
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.error.main,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  whiteSuccess: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    "& button": {
      // Accept button
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.success.dark,
      },
      "&:nth-child(2n)": {
        // Refuse button
        color: theme.palette.success.main,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(3),
  },
}));

const NotificationCard = ({
  data,
  fullWidth = false,
  withoutRead = false,
  withRouter = false,
}) => {
  const [cardStyle, setCardStyle] = useState("");
  const classes = useStyle();
  const { fetchConnectedUser } = useAuth();
  const navigate = useNavigate();
  const { readNotification } = useNotifications();

  const redirectTo = (action) => {
    if (withRouter) {
      // Use the router to redirect
      navigate(action);
    } else {
      // Use default redirect
      window.location.href = action; // Execute the action
    }
  };

  const handleClick = (notificationId, action) => {
    if (withoutRead) {
      if (action !== undefined && action !== "") {
        redirectTo(action);
      }
    } else {
      readNotification(notificationId)
        .then(() => {
          if (action !== undefined && action !== "") {
            redirectTo(action);
          } else {
            fetchConnectedUser(); // Reload the notifications by refreshing the user
          }
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    switch (data.variant) {
      case "primary":
        setCardStyle(classes.primary);
        break;
      case "secondary":
        setCardStyle(classes.secondary);
        break;
      case "info":
        setCardStyle(classes.info);
        break;
      case "warning":
        setCardStyle(classes.warning);
        break;
      case "success":
        setCardStyle(classes.success);
        break;
      case "error":
        setCardStyle(classes.error);
        break;
      case "white-primary":
        setCardStyle(classes.whitePrimary);
        break;
      case "white-secondary":
        setCardStyle(classes.whiteSecondary);
        break;
      case "white-info":
        setCardStyle(classes.whiteInfo);
        break;
      case "white-warning":
        setCardStyle(classes.whiteWarning);
        break;
      case "white-success":
        setCardStyle(classes.whiteSuccess);
        break;
      case "white-error":
        setCardStyle(classes.whiteError);
        break;
      default:
        break;
    }
  }, [data, classes]);

  return (
    <Card
      className={`${classes.card}${fullWidth ? " fullWidth" : ""} ${cardStyle}`}
    >
      <CardContent>
        <Box className={classes.header}>
          <Typography variant="overline" style={{ flex: 1 }}>
            {data.short_description}
          </Typography>
          <Icon>{data.icon}</Icon>
        </Box>
        <Typography variant="h4">{data.title}</Typography>
        <Typography variant="body1" component={"span"}>
          <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
        </Typography>
      </CardContent>
      <CardActions>
        {data.accept_label && (
          <Button
            variant="contained"
            onClick={() => {
              handleClick(data.id, data.accept_action);
            }}
          >
            {data.accept_label}
          </Button>
        )}
        {data.refuse_label && (
          <Button
            onClick={() => {
              handleClick(data.id, data.refuse_action);
            }}
          >
            {data.refuse_label}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default NotificationCard;
