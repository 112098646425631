import React from "react";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { FormHelperText } from "@material-ui/core";
import CotroliaAutocomplete from "../../../../../components/core/input/CotroliaAutocomplete";
import _ from "lodash";
import InterFormControl from "../../InterFormControl";
import { useConfiguration } from "../../../../../hooks/useConfiguration";
import { CarBrand } from "../../../../interventions/types/InterventionTypes";

const FieldCarBrand: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { interventionConfig } = useConfiguration();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl error={!!props.error}>
      <CotroliaAutocomplete
        id="brand-car-label"
        label={t("CarBrand")}
        value={intervention.car.carBrand ?? { id: -1, label: "" }}
        error={!!props.error}
        onChange={(brand?: CarBrand) => {
          setIntervention({
            ...intervention,
            car: { ...intervention.car, carBrand: brand, carModel: undefined },
          });
          props.onChange && props.onChange();
        }}
        getOptions={(searchValue: string) => {
          const brands = interventionConfig.carBrandModel;
          const options: { id: string; label: string }[] = [];
          Object.keys(brands).forEach((key) => {
            options.push({ id: key, label: brands[key].label });
          });
          const filteredOptions = options.filter((opt) =>
            opt.label.toLowerCase().includes(searchValue.toLowerCase()),
          );
          return new Promise((resolve) =>
            resolve(_.sortBy(filteredOptions, (o) => o.label)),
          );
        }}
        getOptionLabel={(option: { id: string; label: string }) => {
          return option?.label ?? "";
        }}
        required
      />
      {props.error && (
        <FormHelperText variant="outlined">{props.error}</FormHelperText>
      )}
    </InterFormControl>
  );
};

export default FieldCarBrand;
