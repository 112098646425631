import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/core/Header";
import CircleButton from "../components/core/CircleButton";
import {
  Box,
  Button,
  Collapse,
  makeStyles,
  Typography,
} from "@material-ui/core";
import HeaderActions from "../components/core/HeaderActions";
import FilterListIcon from "@material-ui/icons/FilterList";
import Content from "../components/core/layout/Content";
import NoIntervention from "../features/interventions/components/list/NoIntervention";
import AppConstant from "../utils/appConstant";
import { useInterventions } from "../features/interventions/hooks/useInterventions";
import Datagrid, { ColumnDef } from "../components/core/Datagrid";
import {
  Intervention,
  InterventionFilter,
  InterventionSort,
  InterventionSortFields,
} from "../features/interventions/types/InterventionTypes";
import { useInterventionStatus } from "../features/interventions/hooks/useInterventionStatus";
import { useDevice } from "../hooks/useDevice";
import { useConfiguration } from "../hooks/useConfiguration";
import moment from "moment";
import NetworkInterventionsFilters from "../features/interventions/components/filters/NetworkInterventionsFilters";
import { Build } from "@material-ui/icons";
import NoFilterResult from "../components/core/NoFilterResult";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: theme.spacing(4),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    justifyContent: "center",
  },
  listContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "& .MuiCard-root": {
      [theme.breakpoints.up("sm")]: {
        width: AppConstant.interventionCardWidthList,
      },
    },
  },
  firstCol: {
    "&:after": {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      content: "''",
      display: "block",
      height: "50%",
    },
  },
}));

const NetworkInterventions: React.FC = () => {
  const { t } = useTranslation();
  const configuration = useConfiguration();
  const device = useDevice();
  const classes = useStyle();
  const [interventions, setInterventions] = useState<Intervention[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<InterventionFilter>({});
  const someFilters = Object.values(filters).some((value) => !!value);
  const [sort, setSort] = useState<InterventionSort>({ rowid: "DESC" });
  const { listIntervention } = useInterventions();
  const { getStatusDisplay } = useInterventionStatus();

  useEffect(() => {
    setLoading(true);
    listIntervention(rowsPerPage, page + 1, filters, sort)
      .then((res) => {
        setInterventions(res.results);
        setTotal(parseInt(res.total));
      })
      .finally(() => setLoading(false));
  }, [filters, listIntervention, page, rowsPerPage, sort]);

  const prepareColumns = (): ColumnDef<Intervention>[] => {
    const carColumns: ColumnDef<Intervention>[] = [
      {
        header: t("NetworkInterHeaderCarBrand"),
        format: (value) =>
          configuration.getCarBrandFromModel(value.carType)?.label ?? "-",
      },
      {
        header: t("NetworkInterHeaderCarType"),
        format: (value) => value.carType?.label ?? "-",
      },
    ];
    const columns: ColumnDef<Intervention>[] = [
      {
        header: t("NetworkInterHeaderRef"),
        format: (value) => (
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            {value.ref}
          </Typography>
        ),
        sortOnField: InterventionSortFields.ref,
        className: classes.firstCol,
        colWidth: "auto",
      },
      {
        header: t("NetworkInterHeaderState"),
        format: (value) => (
          <Typography
            style={{
              color: getStatusDisplay(value.status).textColor,
              backgroundColor: getStatusDisplay(value.status).backgroundColor,
              padding: "4px 12px",
              fontSize: 12,
              fontWeight: 500,
              lineHeight: "12px",
              textTransform: "uppercase",
              textAlign: "center",
              borderRadius: 4,
            }}
          >
            {getStatusDisplay(value.status).label}
          </Typography>
        ),
        sortOnField: InterventionSortFields.fk_statut,
      },
      {
        header: t("NetworkInterHeaderGarageName"),
        format: (value) => value.thirdParty?.name ?? "-",
      },
      {
        header: t("NetworkInterHeaderClientCode"),
        format: (value) => value.thirdParty?.customerCode ?? "-",
      },
      {
        header: t("NetworkInterHeaderShopCode"),
        format: (value) => value.thirdParty?.shopCode ?? "-",
      },
      {
        header: t("NetworkInterHeaderPartType"),
        format: (value) => value.partType?.label ?? "-",
      },
    ];
    columns.push(...(configuration.sector === "1" ? carColumns : []));
    columns.push(
      {
        header: t("NetworkInterHeaderDateCreation"),
        format: (value) => moment.unix(value.dateCreat).format("L"),
        sortOnField: InterventionSortFields.datec,
      },
      {
        header: t("NetworkInterHeaderDateTakeCharge"),
        format: (value) =>
          value.takeCharge ? moment.unix(value.takeCharge).format("L") : "-",
      },
      {
        header: t("NetworkInterHeaderSendPropal"),
        format: (value) =>
          value.propal?.sendPropal
            ? moment.unix(value.propal.sendPropal).format("L")
            : "-",
      },
      {
        header: t("NetworkInterHeaderDateSignedPropal"),
        format: (value) =>
          value.propal?.signedPropal
            ? moment.unix(value.propal.signedPropal).format("L")
            : "-",
      },
    );
    return columns;
  };

  return (
    <>
      <Header
        focus={false}
        title={t("NetworkInterventions")}
        titleIcon={<Build />}
      >
        <HeaderActions>
          {device.isMobile ? (
            <>
              <CircleButton
                color="primary"
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                <FilterListIcon />
              </CircleButton>
            </>
          ) : (
            <>
              <Button
                color="primary"
                endIcon={<FilterListIcon />}
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                {t("ToggleFiltersLabel")}
              </Button>
            </>
          )}
        </HeaderActions>
      </Header>
      <Content>
        {(total > 0 || someFilters) && (
          <Collapse in={displayFilter}>
            <NetworkInterventionsFilters
              applyFilters={(filters) => setFilters(filters)}
            />
          </Collapse>
        )}
        {!isLoading && total === 0 ? (
          <>
            {someFilters ? (
              <NoFilterResult />
            ) : (
              <Box className={classes.root}>
                <NoIntervention
                  title={t("NetworkInterventionsEmptyTitle")}
                  desc={t("NetworkInterventionsEmptyDesc")}
                />
              </Box>
            )}
          </>
        ) : (
          <Datagrid
            rows={interventions}
            rowsPerPage={rowsPerPage}
            page={page}
            total={total}
            enablePageSelector
            onChangePage={(p) => setPage(p)}
            columnsDef={prepareColumns()}
            sort={sort}
            firstColSticky
            onSort={(sort) => setSort(sort)}
            loading={isLoading}
          />
        )}
      </Content>
    </>
  );
};

export default NetworkInterventions;
