import React, { useCallback, useState } from "react";
import {
  InterventionToSave,
  NewIntervention,
  PartRef,
  SavedIntervention,
} from "../types/NewInterventionFormTypes";
import AppConstant from "../../../utils/appConstant";
import moment from "moment";
import { emptyAddress } from "../../../utils/model";
import { useApi } from "../../../hooks/useApi";
import toBase64 from "../../../utils/base64";
import {
  InterventionStatus,
  WarrantyValue,
} from "../../interventions/types/InterventionTypes";
import { useAuth } from "../../auth/hooks/useAuth";
import { ContactType } from "../../user/types/userTypes";

const defaultNewIntervention: NewIntervention = {
  car: {
    customer_effect: "",
    diagnostic: "",
    distance: "",
    frame: "",
    parts_replaced: "",
    registration: "",
    security_code: "",
  },
  part: {
    waranty: WarrantyValue.No,
    refNotVisible: false,
    oldpictures: [],
    pictures: [],
    diagFiles: [],
    clientRef: "",
    description: "",
    frequency: "",
    type: "0",
  },
  shipping: {
    method: AppConstant.shippingMethodPicking,
    pickupDate: moment(),
    closingDates: {
      monday: false,
      saturday: false,
    },
    company: "",
    informations: "",
    phone: "",
  },
  packaging: {
    partNb: 1,
    instructions: false,
    complementaryPart: false,
    address: "",
    complementaryPartInfos: "",
  },
  finish: {},
};

type StepState = "normal" | "tovalidate" | "validated";

interface NewInterventionContextType {
  stepIndex: number;
  stepState: StepState;
  intervention: NewIntervention;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setStepState: React.Dispatch<React.SetStateAction<StepState>>;
  setIntervention: React.Dispatch<React.SetStateAction<NewIntervention>>;
  sendForm: (intervention: NewIntervention) => Promise<number>;
  saveForm: (intervention: NewIntervention) => Promise<number>;
  resetIntervention: () => void;
  loadFromSavedIntervention: (id: string) => Promise<void>;
}

const NewInterventionContext = React.createContext<NewInterventionContextType>(
  {} as NewInterventionContextType,
);

const NewInterventionProvider: React.FC = (props) => {
  const { call } = useApi();
  const { user } = useAuth();
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [stepState, setStepState] = useState<StepState>("normal");

  const initIntervention = (): NewIntervention => {
    const newInter = { ...defaultNewIntervention };

    if (user?.thirdparty) {
      const thirdpartyAddress = user.thirdparty.address;
      newInter.shipping.company = user.thirdparty.name;
      newInter.shipping.address = { ...emptyAddress };
      newInter.shipping.address.fullAddress = thirdpartyAddress.fullAddress;
      newInter.shipping.address.city = thirdpartyAddress.city;
      newInter.shipping.address.street = thirdpartyAddress.street;
      newInter.shipping.address.zipCode = thirdpartyAddress.zipCode;
      newInter.shipping.address.countryCode = thirdpartyAddress.countryCode;

      newInter.shipping.phone =
        user.thirdparty.phone !== null ? user.thirdparty.phone : "";

      const contactList = user.thirdparty.contactList;
      if (contactList) {
        for (let i = 0; i < contactList.length; i++) {
          switch (contactList[i].type) {
            case ContactType.Billing:
              newInter.finish.billingContact = contactList[i];
              break;
            case ContactType.Main:
              newInter.finish.mainContact = contactList[i];
              break;
            case ContactType.Tech:
            default:
              newInter.finish.technicalContact = contactList[i];
              break;
          }
        }
      }
    }
    const now = moment();
    // If pickup date is past, we set it to now
    if (newInter.shipping.pickupDate <= now) {
      newInter.shipping.pickupDate = now;
    }
    // And if pickup date is today after 12h, we set it to the next day
    if (
      newInter.shipping.pickupDate.isSame(now, "day") &&
      newInter.shipping.pickupDate.hour() >= 12
    ) {
      newInter.shipping.pickupDate = now.add(1, "days");
    }

    if (
      user?.individual &&
      newInter.shipping.method !== AppConstant.shippingMethodPersonal
    ) {
      newInter.shipping.method = AppConstant.shippingMethodPersonal;
    }
    return newInter;
  };

  const [intervention, setIntervention] =
    useState<NewIntervention>(initIntervention());

  const sendForm = async (intervention: NewIntervention): Promise<number> => {
    const interData = prepareInterventionToSave(
      intervention,
      AppConstant.interStatusTransmited,
    );

    const res = await call("/supercotrolia/interventions", { data: interData });

    let uploadErrors = 0;
    if (res.id) {
      uploadErrors = await savePictures(
        interData.pictures,
        intervention,
        res.id,
      );
    }
    return uploadErrors;
  };

  const saveForm = async (intervention: NewIntervention): Promise<number> => {
    const interData = prepareInterventionToSave(
      intervention,
      AppConstant.interStatusTransmited,
    );

    interData.shipping.pickupDate = interData.shipping.pickupDate
      ? moment(interData.shipping.pickupDate).unix().toString()
      : undefined;

    const res = await call("/supercotrolia/interventions/save", {
      data: interData,
    });

    let uploadErrors = 0;
    if (res.id) {
      uploadErrors = await savePictures(
        interData.pictures,
        intervention,
        res.id,
      );
    }
    return uploadErrors;
  };

  const resetIntervention = () => {
    setIntervention(defaultNewIntervention);
  };

  const savePictures = async (
    pictures: File[],
    intervention: NewIntervention,
    interId: string,
  ): Promise<number> => {
    let uploadErr = 0;
    // Upload pictures
    for (let i = 0; i < pictures.length; i++) {
      if (!intervention.part.oldpictures.includes(pictures[i])) {
        const fileContent = await toBase64(pictures[i]);
        await call("/supercotrolia/interventions/" + interId + "/document", {
          data: {
            filename: pictures[i].name,
            filecontent: fileContent,
          },
        }).catch((err) => {
          console.error(err);
          uploadErr++;
        });
      }
    }
    // Delete pictures
    if (
      intervention.part.oldpictures.length > 0 &&
      intervention.part.pictures !== intervention.part.oldpictures
    ) {
      for (let i = 0; i < intervention.part.oldpictures.length; i++) {
        if (!pictures.includes(intervention.part.oldpictures[i])) {
          await call(
            "/supercotrolia/interventions/" +
              interId +
              "/document?filename=" +
              intervention.part.oldpictures[i].name,
            { method: "DELETE" },
          ).catch((err) => {
            console.error(err);
            uploadErr++;
          });
        }
      }
    }
    return uploadErr;
  };

  const prepareInterventionToSave = (
    inter: NewIntervention,
    status: InterventionStatus,
  ): InterventionToSave => {
    const interToSave: InterventionToSave = {
      id: inter.id?.toString(),
      status: status,
      partNbr: inter.packaging.partNb,
      waranty: inter.part.waranty,
      warrantyPrevInter: inter.part.warrantyPrevInter ?? {
        id: null,
        label: "",
      },
      description: inter.part.description ? inter.part.description : "Cotrolia",
      clientRef: inter.part.clientRef,
      frequency: inter.part.frequency !== "" ? inter.part.frequency : null,
      refNotVisible: inter.part.refNotVisible,
      pictures: [...inter.part.pictures, ...inter.part.diagFiles],
      part: {
        type: inter.part.type,
        manufacturer: inter.part.manufacturer ?? {
          id: null,
          label: "",
        },
        ref: inter.part.ref ?? { id: null, ref: "" },
      },

      car: {
        frame: inter.car.frame,
        carBrand: inter.car.carBrand ?? { id: null, ref: "" },
        carModel: inter.car.carModel ?? { id: null, ref: "" },
        distance: inter.car.distance,
        registration: inter.car.registration,
        security_code: inter.car.security_code,
      },
      customer_effect: inter.car.customer_effect,
      diagnostic: inter.car.diagnostic,
      parts_replaced: inter.car.parts_replaced,
      shipping: {
        method: inter.shipping.method,
        pickupDate: inter.shipping.pickupDate
          ? moment(inter.shipping.pickupDate).format("YYYY-MM-DD hh:mm:ss")
          : undefined,
        company: inter.shipping.company,
        informations: inter.shipping.informations,
        address: inter.shipping.address?.fullAddress,
        addressNumber: inter.shipping.address?.street,
        zip: inter.shipping.address?.zipCode,
        city: inter.shipping.address?.city,
        countryCode: inter.shipping.address?.countryCode,
        phone: inter.shipping.phone,
        closingDates: {
          monday: inter.shipping.closingDates.monday,
          saturday: inter.shipping.closingDates.saturday,
        },
      },
      mainContact: inter.finish.mainContact,
      billingContact: inter.finish.billingContact,
      technicalContact: inter.finish.technicalContact,
      privateNote: `Intervention créée depuis le portail v${import.meta.env.VITE_VERSION}${import.meta.env.VITE_BETA === "true" ? " (beta)" : ""}`,
      packing_instructions: inter.packaging.complementaryPartInfos,
    };

    return interToSave;
  };

  const base64ToFile = (dataurl: string, filename: string) => {
    const bstr = atob(dataurl);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename);
  };

  const loadFromSavedIntervention = useCallback(
    (id: string) =>
      call("/supercotrolia/interventions/save?id=" + id).then(
        (savedInter: SavedIntervention) => {
          const savedPictures = savedInter.pictures.map((picture) =>
            base64ToFile(picture.content, picture.name),
          );
          const savedAddress = { ...emptyAddress };
          if (savedInter.shipping.address) {
            savedAddress.fullAddress = savedInter.shipping.address;
          } else {
            savedAddress.city = savedInter.shipping.city ?? "";
            savedAddress.country = "";
            savedAddress.countryCode = savedInter.shipping.countryCode ?? "";
            savedAddress.zipCode = savedInter.shipping.zip ?? "";
            savedAddress.street = savedInter.shipping.addressNumber ?? "";
            savedAddress.fullAddress = savedInter.shipping.fullAddress ?? "";
          }
          setIntervention({
            ...intervention,
            id: savedInter.id!,
            part: {
              ...intervention.part,
              waranty:
                savedInter.waranty === "1"
                  ? WarrantyValue.Yes
                  : WarrantyValue.No,
              warrantyPrevInter: savedInter.warrantyPrevInter?.id
                ? savedInter.warrantyPrevInter
                : undefined,
              type: savedInter.part.type ? savedInter.part.type : "",
              manufacturer: savedInter.part.manufacturer?.id
                ? savedInter.part.manufacturer
                : undefined,
              description: savedInter.description ? savedInter.description : "",
              ref: (savedInter.part.ref as PartRef | undefined)?.id
                ? (savedInter.part.ref as PartRef | undefined)
                : undefined,
              refNotVisible: savedInter.refNotVisible,
              clientRef: savedInter.clientRef ? savedInter.clientRef : "",
              frequency: savedInter.frequency ? savedInter.frequency : "",
              pictures: savedPictures,
              oldpictures: savedPictures, // keep list of previously saved pictures if we delete some during the process
            },
            car: {
              ...intervention.car,
              frame: savedInter.car.frame ? savedInter.car.frame : "",
              carBrand: savedInter.car.carBrand?.id
                ? savedInter.car.carBrand
                : undefined,
              carModel: savedInter.car.carModel?.id
                ? savedInter.car.carModel
                : undefined,
              distance: savedInter.car.distance ? savedInter.car.distance : "",
              security_code: savedInter.car.security_code
                ? savedInter.car.security_code
                : "",
              registration: savedInter.car.registration
                ? savedInter.car.registration
                : "",
              customer_effect: savedInter.car.customer_effect
                ? savedInter.car.customer_effect
                : "",
              diagnostic: savedInter.car.diagnostic
                ? savedInter.car.diagnostic
                : "",
              parts_replaced: savedInter.car.parts_replaced
                ? savedInter.car.parts_replaced
                : "",
            },
            shipping: {
              ...intervention.shipping,
              method: savedInter.shipping.method
                ? parseInt(savedInter.shipping.method)
                : AppConstant.shippingMethodPicking,
              pickupDate: moment(savedInter.shipping.pickupDate),
              company: savedInter.shipping.company
                ? savedInter.shipping.company
                : intervention.shipping.company,
              informations: savedInter.shipping.informations,
              address: savedAddress,
              phone: savedInter.shipping.phone,
              closingDates: {
                monday: savedInter.shipping.closingDates.monday,
                saturday: savedInter.shipping.closingDates.saturday,
              },
            },
            packaging: {
              ...intervention.packaging,
              partNb: savedInter.partNbr ? parseInt(savedInter.partNbr) : 1,
              instructions: false,
              address: "",
              complementaryPart: false,
            },
            finish: {
              ...intervention.finish,
              mainContact: savedInter.mainContact,
              billingContact: savedInter.billingContact,
              technicalContact: savedInter.technicalContact,
            },
          });
        },
      ),
    [call, intervention],
  );

  return (
    <NewInterventionContext.Provider
      value={{
        stepIndex,
        setStepIndex,
        intervention,
        setIntervention,
        stepState,
        setStepState,
        sendForm,
        resetIntervention,
        saveForm,
        loadFromSavedIntervention,
      }}
      {...props}
    >
      {props.children}
    </NewInterventionContext.Provider>
  );
};

export const useNewInterventionForm = () =>
  React.useContext(NewInterventionContext);

export default NewInterventionProvider;
