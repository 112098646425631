import React from "react";
import { useTranslation } from "react-i18next";
import { FormHelperText } from "@material-ui/core";
import CotroliaFormControl from "../../../../components/core/input/CotroliaFormControl";
import CotroliaTextField from "../../../../components/core/input/CotroliaTextField";

const FieldDistributorEmail: React.FC<{
  error?: string;
  value?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <CotroliaFormControl style={{ margin: 0 }} error={!!props.error}>
      <CotroliaTextField
        id="distributoremail"
        label={t("DistributorEmail")}
        value={props.value}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => {
          props.onChange && props.onChange(e.target.value);
        }}
        required
        disabled={props.disabled}
        error={!!props.error}
      />
      <FormHelperText variant="outlined">{props.error ?? ""}</FormHelperText>
    </CotroliaFormControl>
  );
};

export default FieldDistributorEmail;
