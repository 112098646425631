import React, { useState } from "react";
import { FormHelperText, Grid, makeStyles, useTheme } from "@material-ui/core";
import CotroliaInputLabel from "./CotroliaInputLabel";
import CotroliaSwitch from "./CotroliaSwitch";
import CotroliaTextField from "./CotroliaTextField";
const useStyle = makeStyles((theme) => ({
  switchtextfield: {
    marginTop: theme.spacing(1.5),
    width: "100%",

    // Label Color
    "& .MuiInputLabel-filled": {
      color: theme.palette.primary.main,
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
      "&.Mui-disabled": {
        color: theme.palette.grey[400],
      },
    },

    // Input form
    "& .MuiFilledInput-root": {
      borderRadius: theme.spacing(1) + 2,
      backgroundColor: theme.palette.grey[100],
      "&::before": {
        borderBottom: "none",
      },
      "&:hover:before": {
        borderBottom: "none",
      },
      // Border bottom of the input
      "&.MuiFilledInput-underline": {
        "&:after": {
          width: `calc(100% - ${(theme.spacing(1) + 2) * 2}px)`, // We substract border-radius of each corner
          left: theme.spacing(1) + 2, // We center the line
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        "&.Mui-error:after": {
          borderColor: theme.palette.error.main,
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.text.primary,

          "& .MuiSvgIcon-root": {
            color: theme.palette.grey[400],
          },
        },
      },
    },
  },
}));

interface CotroliaSwitchTextFieldPropsType {
  label: string;
  helper: string;
  inputLabel: string;
  inputDefaultValue?: string;
  onChange: (checked: boolean, value?: string) => void;
  error?: boolean;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
}

const CotroliaSwitchTextField: React.FC<CotroliaSwitchTextFieldPropsType> = (
  props,
) => {
  const theme = useTheme();
  const classes = useStyle();
  const [checked, setChecked] = useState<boolean>(!!props.inputDefaultValue);
  const [inputValue, setInputValue] = useState<string>(
    props.inputDefaultValue ?? "",
  );
  return (
    <>
      <Grid
        container
        style={{
          width: "100%",
          backgroundColor: theme.palette.grey[50],
          padding: theme.spacing(1.5),
          borderRadius: 10,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            flexWrap: "nowrap",
          }}
        >
          <Grid
            container
            item
            direction="column"
            justifyContent="space-between"
            style={{ paddingRight: theme.spacing(1) }}
          >
            <CotroliaInputLabel
              id="waranty-label"
              shrink={false}
              variant="standard"
              style={{
                position: "relative",
                margin: 0,
                transform: "unset",
                lineHeight: "24px",
              }}
            >
              {props.label}
            </CotroliaInputLabel>
            <FormHelperText
              id="waranty-helper"
              style={{
                margin: 0,
                lineHeight: "20px",
                fontSize: 12,
                fontWeight: 400,
                color: theme.palette.text.primary,
                opacity: 0.7,
              }}
            >
              {props.helper}
            </FormHelperText>
          </Grid>
          <Grid>
            <CotroliaSwitch
              id="waranty"
              aria-describedby="waranty-helper"
              checked={checked}
              defaultChecked={!!inputValue}
              onChange={(_e, value: boolean) => {
                const newInputValue = value ? inputValue : "";
                setChecked(value);
                setInputValue(newInputValue);
                props.onChange && props.onChange(value, newInputValue);
              }}
            />
          </Grid>
        </Grid>
        {checked && (
          <CotroliaTextField
            label={props.inputLabel}
            value={inputValue}
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) => {
              setInputValue(e.target.value);
              props.onChange && props.onChange(checked, e.target.value);
            }}
            required
            multiline={props.multiline}
            rows={props.rows}
            error={!!props.error}
            className={classes.switchtextfield}
          />
        )}
      </Grid>
    </>
  );
};

export default CotroliaSwitchTextField;
