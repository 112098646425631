import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FilterListIcon from "@material-ui/icons/FilterList";

const useStyle = makeStyles((theme) => ({
  root: {
    marginBottom: "2em",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(1)}px 0`,
    "& > .MuiFormControl-root": {
      flexGrow: 1,
      margin: theme.spacing(1),
      minWidth: 150,
    },
  },
  actionBtn: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    "& button": {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

const InvoicesFilters = ({ applyFilters }) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const handleResetClick = () => {
    applyFilters(null);
  };

  const handleFilterClick = () => {
    let filters = {};

    applyFilters(filters);
  };

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <FilterListIcon style={{ marginRight: 5 }} />
        <Typography variant="h6">{t("Filters")}</Typography>
      </Box>

      <Box className={classes.filterContainer}></Box>
      <Box className={classes.actionBtn}>
        <Button variant="outlined" color="primary" onClick={handleResetClick}>
          {t("Reset")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleFilterClick}>
          {t("Filter")}
        </Button>
      </Box>
    </Box>
  );
};

export default InvoicesFilters;
