import React, { useEffect } from "react";
import "./App.scss";
import AuthenticatedApp from "./layouts/AuthenticatedApp";
import { ThemeProvider } from "@material-ui/core";
import { MenuProvider } from "./context/MenuContext";
import FullPageLoader from "./components/core/FullPageLoader";
import UnderMaintenance from "./components/core/UnderMaintenance";
import {
  Route,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { RequireAuth } from "./features/auth/components/RequireAuth";
import { RequireNoAuth } from "./features/auth/components/RequireNoAuth";
import Login from "./pages/Login";
import InterventionDetail from "./features/interventions/components/detail/InterventionDetail";
import LatestIntervention from "./features/interventions/components/detail/LatestIntervention";
import InterventionProposal from "./features/interventions/components/proposal/InterventionProposal";
import InterventionInvoice from "./features/interventions/components/invoice/InterventionInvoice";
import InterventionInvoiceDiffered from "./features/interventions/components/invoice-differed/InterventionInvoiceDiffered";
import MyInterventions from "./pages/MyInterventions";
import NewIntervention from "./pages/NewIntervention";
import Dashboard from "./pages/Dashboard";
import Contact from "./pages/Contact";
import NewInterventionProvider from "./features/newintervention/context/NewInterventionContext";
import Profile from "./pages/Profile";
import UnauthenticatedApp from "./layouts/UnauthenticatedApp";
import { AuthProvider } from "./features/auth/context/AuthContext";
import { useConfiguration } from "./hooks/useConfiguration";
import { useSentry } from "./hooks/useSentry";
import useCustomTheme from "./hooks/useCustomTheme";
import Garages from "./pages/Garages";
import Distributors from "./pages/Distributors";
import Invoices from "./pages/Invoices";
import Statistics from "./pages/Statistics";
import NetworkInterventions from "./pages/NetworkInterventions";
import Register from "./pages/Register";
import ResetPasswordForm from "./features/auth/components/ResetPasswordForm";
import ForgetPasswordForm from "./features/auth/components/ForgetPasswordForm";
import ValidateAccountForm from "./features/auth/components/ValidateAccountForm";
import GarageEditPage from "./pages/GarageEditPage";
import ValidateAccountFormLegacy from "./features/auth/components/ValidateAccountFormLegacy";
import DistributorEditPage from "./pages/DistributorEditPage";

const App: React.FC = () => {
  const { initSentry } = useSentry();

  useEffect(() => initSentry(), [initSentry]);

  const routes = createRoutesFromElements(
    <Route element={<AuthProvider />}>
      <Route element={<RequireNoAuth />}>
        <Route element={<UnauthenticatedApp />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget-password" element={<ForgetPasswordForm />} />
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          <Route
            path="/validate-account"
            element={<ValidateAccountFormLegacy />} // Keeped because this route is always referenced in backend email templates, Despite uncertain usefulness
          />
          <Route path="/account-validation" element={<ValidateAccountForm />} />
        </Route>
      </Route>
      <Route element={<RequireAuth />}>
        <Route element={<AuthenticatedApp />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/myinterventions" element={<MyInterventions />} />
          <Route
            path="/intervention/new"
            element={
              <NewInterventionProvider>
                <NewIntervention />
              </NewInterventionProvider>
            }
          />
          <Route path="/intervention/:id" element={<InterventionDetail />} />
          <Route path="/intervention/latest" element={<LatestIntervention />} />
          <Route
            path="/intervention/:id/proposal"
            element={<InterventionProposal />}
          />
          <Route
            path="/intervention/:id/invoice"
            element={<InterventionInvoice />}
          />
          <Route
            path="/intervention/:id/invoice-differed"
            element={<InterventionInvoiceDiffered />}
          />

          <Route
            path="/networkinterventions"
            element={<NetworkInterventions />}
          />
          <Route path="/garages" element={<Garages />} />
          <Route path="/garage/new" element={<GarageEditPage key={"new"} />} />
          <Route
            path="/garage/:id/edit"
            element={<GarageEditPage key={"edit"} />}
          />
          <Route path="/distributors" element={<Distributors />} />
          <Route
            path="/distributor/new"
            element={<DistributorEditPage key={"new"} />}
          />
          <Route
            path="/distributor/:id/edit"
            element={<DistributorEditPage key={"edit"} />}
          />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
      </Route>
    </Route>,
  );

  return <AppContent routes={routes} />;
};

const AppContent: React.FC<{ routes: RouteObject[] }> = (props) => {
  const configuration = useConfiguration();
  const { theme } = useCustomTheme();
  return (
    <>
      {configuration.loading ? (
        <FullPageLoader />
      ) : configuration.isUnderMaintenance ? (
        <UnderMaintenance title={configuration.name} />
      ) : (
        <ThemeProvider theme={theme}>
          <MenuProvider>
            <RouterProvider router={createBrowserRouter(props.routes)} />
          </MenuProvider>
        </ThemeProvider>
      )}
    </>
  );
};

export default App;
