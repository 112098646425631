import React from "react";
import { makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useConfiguration } from "../hooks/useConfiguration";

import CompanyLogo from "../components/core/CompanyLogo";
import { Outlet } from "react-router-dom";
import AuthFooter from "../features/auth/components/AuthFooter";
import { useDevice } from "../hooks/useDevice";

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    margin: "0",
    display: "flex",
    minHeight: "100vh",
    boxSizing: "border-box",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(4),
  },
  contentContainer: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 600,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "& > h5": {
        textAlign: "center",
      },
      "& > img": {
        maxHeight: 100,
      },
    },
  },
  footer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const UnauthenticatedApp: React.FC = () => {
  const theme = useTheme();
  const device = useDevice();
  const classes = useStyle();
  const { t } = useTranslation();
  const configuration = useConfiguration();
  const declination =
    configuration.themeDeclination.indexOf("dcontent") !== -1
      ? "light"
      : "dark";

  return (
    <div
      className="App"
      style={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: "100vh",
      }}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <CompanyLogo
            variant={device.isMobile ? "full" : "full"}
            declination={declination}
          />
          <Typography
            variant="h5"
            style={{ padding: `0 ${theme.spacing(1)}px` }}
          >
            {t("MainSentence")}
          </Typography>
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
        <div className={classes.footer}>
          <AuthFooter />
        </div>
      </div>
    </div>
  );
};

export default UnauthenticatedApp;
