import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import CotroliaSwitchTextField from "../../../../../components/core/input/CotroliaSwitchTextField";
import { FormHelperText } from "@material-ui/core";

const FieldPartsReplaced: React.FC<{
  error?: string;
  onChange?: (checked: boolean) => void;
}> = (props) => {
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl error={!!props.error} id="parts-replaced">
      <CotroliaSwitchTextField
        label={t("PartsReplaced")}
        helper={t("PartsReplacedHelper")}
        inputLabel={t("PartsReplacedInput")}
        inputDefaultValue={intervention.car.parts_replaced}
        onChange={(checked: boolean, value?: string) => {
          setIntervention({
            ...intervention,
            car: { ...intervention.car, parts_replaced: value },
          });
          props.onChange && props.onChange(checked);
        }}
        required
        multiline
        rows={4}
        error={!!props.error}
      />
      {props.error && (
        <FormHelperText variant="outlined">{props.error}</FormHelperText>
      )}
    </InterFormControl>
  );
};

export default FieldPartsReplaced;
