import React from "react";
import { Box, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    "& > svg": {
      height: 300,
    },
  },
  text: {
    marginTop: theme.spacing(2),
  },
}));

const NoFilterResult: React.FC = () => {
  const theme = useTheme();
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.root}>
        <svg viewBox="0 0 643 447.5">
          <path
            d="M891.62348,313.02059H308.47961a29.96193,29.96193,0,0,1-29.92806-29.92807V255.88519a29.96193,29.96193,0,0,1,29.92806-29.92807H891.62348a29.96193,29.96193,0,0,1,29.92807,29.92807v27.20733A29.96193,29.96193,0,0,1,891.62348,313.02059ZM308.47961,227.77094a28.14617,28.14617,0,0,0-28.11424,28.11425v27.20733a28.14617,28.14617,0,0,0,28.11424,28.11425H891.62348a28.14617,28.14617,0,0,0,28.11424-28.11425V255.88519a28.14617,28.14617,0,0,0-28.11424-28.11425Z"
            transform="translate(-278.55155 -225.95712)"
            fill="#3f3d56"
          />
          <rect
            x="57.1354"
            y="26.30042"
            width="421.71368"
            height="34.46262"
            rx="17.23129"
            fill="#e6e6e6"
          />
          <rect
            x="107.92243"
            y="177.43018"
            width="421.71368"
            height="34.46262"
            rx="17.23129"
            fill={theme.palette.primary.main}
          />
          <rect
            x="107.92243"
            y="287.00423"
            width="421.71368"
            height="34.46262"
            rx="17.23129"
            fill="#e6e6e6"
          />
          <rect
            x="107.92243"
            y="396.57828"
            width="421.71368"
            height="34.46262"
            rx="17.23129"
            fill="#e6e6e6"
          />
          <path
            d="M864.86391,288.10449a2.71409,2.71409,0,0,1-3.7799.66654l-14.80667-10.30275a2.714,2.714,0,1,1,3.11336-4.44644l14.80667,10.30275A2.71408,2.71408,0,0,1,864.86391,288.10449Z"
            transform="translate(-278.55155 -225.95712)"
            fill={theme.palette.primary.main}
          />
          <path
            d="M848.054,276.39907a19.26672,19.26672,0,1,1-4.73174-26.83326A19.28854,19.28854,0,0,1,848.054,276.39907Zm-27.61937-19.33883a14.45,14.45,0,1,0,20.12494-3.5488A14.46638,14.46638,0,0,0,820.43461,257.06024Z"
            transform="translate(-278.55155 -225.95712)"
            fill={theme.palette.primary.main}
          />
          <path
            d="M874.84562,454.28942H325.25747a.90691.90691,0,1,1,0-1.81382H874.84562a.90691.90691,0,1,1,0,1.81382Z"
            transform="translate(-278.55155 -225.95712)"
            fill="#3f3d56"
          />
          <path
            d="M874.84562,563.90864H325.25747a.90691.90691,0,0,1,0-1.81382H874.84562a.90691.90691,0,0,1,0,1.81382Z"
            transform="translate(-278.55155 -225.95712)"
            fill="#3f3d56"
          />
          <path
            d="M874.84562,673.52741H325.25747a.90691.90691,0,0,1,0-1.81382H874.84562a.90691.90691,0,1,1,0,1.81382Z"
            transform="translate(-278.55155 -225.95712)"
            fill="#3f3d56"
          />
        </svg>

        <Box className={classes.text}>
          <Typography variant="h6">{t("ListNoResult")}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default NoFilterResult;
