import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CotroliaPassword from "../../../components/core/input/CotroliaPassword";
import { useAlert } from "react-alert";
import { useAuth } from "../hooks/useAuth";
import PasswordChecklist from "react-password-checklist";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { useApi } from "../../../hooks/useApi";

const useStyle = makeStyles((theme) => ({
  inputContainer: {
    width: "100%",
    margin: `${theme.spacing(1)}px 0`,
  },
  btn: {
    width: "100%",
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  passwordValidation: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    margin: `0 0 ${theme.spacing(1)}px 0 !important`,
    padding: `${theme.spacing(1)}px !important`,
    fontFamily: theme.typography.fontFamily,
  },
}));

const emptyError = {
  value: false,
  msg: "",
};

const RegisterFormStep2 = ({ handlePrev, values, handlers }) => {
  const { t } = useTranslation();
  const { call } = useApi();
  const classes = useStyle();
  const alert = useAlert();
  const auth = useAuth();
  const theme = useTheme();
  const [passwordError, setPasswordError] = useState(emptyError);
  const [rePasswordError, setRePasswordError] = useState(emptyError);
  const [loading, setLoading] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const { i18n } = useTranslation();

  const formatValuesForApi = () => {
    let register = {};
    register.email = values.email;
    register.lastname = values.name;
    register.firstname = values.firstname;
    register.password = values.password;
    register.type = values.professional ? "professional" : "particular";
    register.thirdpartyName = values.thirdparty;
    register.siret = values.siret;
    register.vat = values.vat;
    register.language = i18n.language;

    return register;
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      const datas = formatValuesForApi();
      await call("/supercotrolia/register", { data: datas });
      await auth.login(datas.email, datas.password);
    } catch (err) {
      setLoading(false);
      let errMsg = t("InternalError");
      if (err.status && err.status === 403) {
        // SIRET or VAT already exists in database
        if (err.data.error.message.includes("SIRET")) {
          errMsg = t("RegisterErrorSiretAlreadyExists");
        } else if (err.data.error.message.includes("VAT")) {
          errMsg = t("RegisterErrorVatAlreadyExists");
        } else {
          // User email already exists in database
          errMsg = t("RegisterErrorEmailAlreadyExists");
        }
      } else if (err.status && err.status === 400) {
        // Missing required fields for professional account
        errMsg = t("RegisterErrorMissingRequiredFields");
      }

      alert.show(errMsg, {
        title: t("Error"),
        close: () => {
          alert.remove(alert);
        },
        type: "error",
      });
    }
  };

  const isDisabled = () => {
    let ret = false;
    if (passwordError.value || rePasswordError.value || !validPassword) {
      ret = true;
    } else if (values.password === "" || values.rePassword === "") {
      ret = true;
    } else if (loading) {
      ret = true;
    }

    return ret;
  };

  return (
    <>
      <CotroliaPassword
        className={classes.inputContainer}
        label={t("Password")}
        value={values.password}
        onChange={(value) => {
          setPasswordError(emptyError);
          handlers.setPassword(value);

          // [#116] Fix repassword validation
          if (value === values.rePassword) {
            setRePasswordError(emptyError);
          }
        }}
        error={passwordError.value}
        helperText={passwordError.value ? passwordError.msg : null}
      />
      <CotroliaPassword
        className={classes.inputContainer}
        label={t("RePassword")}
        value={values.rePassword}
        onChange={(value) => {
          if (value !== values.password) {
            setRePasswordError({ value: true, msg: t("InvalidRePassword") });
          } else {
            setRePasswordError(emptyError);
          }

          handlers.setRePassword(value);
        }}
        error={rePasswordError.value}
        helperText={rePasswordError.value ? rePasswordError.msg : null}
      />
      <PasswordChecklist
        rules={["minLength", "specialChar", "number", "capital", "match"]}
        minLength={8}
        value={values.password}
        valueAgain={values.rePassword}
        onChange={(isValid) => {
          setValidPassword(isValid);
        }}
        messages={{
          minLength: t("PasswordRuleMinLength"),
          specialChar: t("PasswordRuleSpecialChar"),
          number: t("PasswordRuleNumber"),
          capital: t("PasswordRuleCapital"),
          match: t("PasswordRuleMatch"),
        }}
        iconComponents={{
          ValidIcon: (
            <CheckIcon style={{ color: theme.palette.success.main }} />
          ),
          InvalidIcon: (
            <ClearIcon style={{ color: theme.palette.error.main }} />
          ),
        }}
        className={classes.passwordValidation}
      />
      <Button
        variant="contained"
        color="secondary"
        className={classes.btn}
        endIcon={
          loading ? <CircularProgress size={24} /> : <ArrowForwardIcon />
        }
        onClick={handleClick}
        disabled={isDisabled()}
      >
        {t("CreateMyAccount")}
      </Button>
      <Button
        color="secondary"
        variant="outlined"
        className={classes.btn}
        startIcon={<ArrowBackIcon />}
        onClick={handlePrev}
        style={{ marginTop: 10 }}
      >
        {t("Previous")}
      </Button>
    </>
  );
};

export default RegisterFormStep2;
