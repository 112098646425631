import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const RepturnIcon: React.FC = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 65 64">
      <path
        d="M34.3527 29.2005C42.2379 23.9772 44.7049 20.2118 45.4734 18.2764C42.0607 15.0083 37.4315 13 32.3334 13C21.8401 13 13.3335 21.5065 13.3335 31.9997C13.3335 33.8664 13.6041 35.6698 14.1056 37.3738C16.5787 38.4014 23.6243 36.3075 34.3527 29.2005Z"
        fill="#a8ba05"
      />
      <path
        d="M49.6477 24.1645C48.9051 23.8746 47.7008 23.7426 45.8179 24.3122C41.9108 25.4948 36.4234 28.2204 27.6871 35.2484C19.6801 41.6899 18.8194 43.8827 18.9333 45.4685C22.369 48.8867 27.1042 50.9999 32.3337 50.9999C42.827 50.9999 51.3336 42.4934 51.3336 32.0002C51.3336 29.2059 50.7309 26.5532 49.6477 24.1645Z"
        fill="#a8ba05"
      />
    </SvgIcon>
  );
};
