import { Box, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router";
import { Description, EmojiTransportation } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    margin: theme.spacing(2, 0),
    textAlign: "center",
  },
  margin: {
    margin: theme.spacing(1, 0),
  },
}));

const NoInvoice = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const navigate = useNavigate();

  return (
    <Box className={classes.root}>
      <Description fontSize="large" />
      <Typography variant="h5" className={classes.margin}>
        {t("NoInvoice")}
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        className={classes.margin}
      >
        {t("NoInvoiceDesc")}
      </Typography>
    </Box>
  );
};

export default NoInvoice;
