import React from "react";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import CotroliaAddress from "../../../../../components/core/input/CotroliaAddress";
import { Address } from "../../../../companies/types/CompaniesTypes";

const FieldAddress: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <CotroliaAddress
      label={t("Address")}
      onChange={(value: Address) => {
        setIntervention({
          ...intervention,
          shipping: { ...intervention.shipping, address: value },
        });
        props.onChange && props.onChange();
      }}
      defaultAddress={intervention.shipping.address}
      required
      error={!!props.error}
      errorMsg={props.error}
    />
  );
};

export default FieldAddress;
