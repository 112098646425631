import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import CotroliaTextField from "../../../../../components/core/input/CotroliaTextField";
import { FormHelperText } from "@material-ui/core";

const FieldSecurityCode: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl variant="filled">
      <CotroliaTextField
        id="securitycode"
        label={t("SecurityCodeCotrolia")}
        value={intervention.car.security_code}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => {
          setIntervention({
            ...intervention,
            car: { ...intervention.car, security_code: e.target.value },
          });
          props.onChange && props.onChange();
        }}
      />
      <FormHelperText id="securitycode-inter-helper">
        {t("SecurityCodeInterHelper")}
      </FormHelperText>
    </InterFormControl>
  );
};

export default FieldSecurityCode;
