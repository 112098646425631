import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { FormHelperText } from "@material-ui/core";
import CotroliaAutocomplete from "../../../../../components/core/input/CotroliaAutocomplete";
import { useApi } from "../../../../../hooks/useApi";
import { PartManufacturer } from "../../../../interventions/types/InterventionTypes";

const FieldManufacturer: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { call } = useApi();
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();

  const searchManufacturer = (searchValue: string) => {
    return call(
      `/supercotrolia/dictionary?table=c_supercotrolia_manufacturer&searchField=label&searchValue=${searchValue}`,
    );
  };

  return (
    <InterFormControl error={!!props.error}>
      <CotroliaAutocomplete
        id="manufacturer"
        label={t("PartManufacturer")}
        value={intervention.part.manufacturer ?? { id: -1, label: "" }}
        required
        error={!!props.error}
        onChange={(newValue: PartManufacturer) => {
          setIntervention({
            ...intervention,
            part: {
              ...intervention.part,
              manufacturer: newValue,
            },
          });
          props.onChange && props.onChange();
        }}
        getOptions={(searchValue: string) =>
          searchManufacturer(searchValue).then((res) => {
            const ret: PartManufacturer[] = [];
            Object.keys(res).forEach((key) => {
              ret.push({ id: key, label: res[key] });
            });
            return ret;
          })
        }
        getOptionLabel={(option: PartManufacturer) => {
          return option?.label ?? "";
        }}
      />
      {props.error && (
        <FormHelperText variant="outlined">{props.error}</FormHelperText>
      )}
    </InterFormControl>
  );
};

export default FieldManufacturer;
