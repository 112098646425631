import React from "react";
import Content from "../components/core/layout/Content";
import Header from "../components/core/Header";
import { useTranslation } from "react-i18next";
import { PieChartOutlined } from "@material-ui/icons";
import { Box, makeStyles, Typography } from "@material-ui/core";

const Statistics: React.FC = () => {
  const classes = makeStyles((theme) => ({
    root: {
      backgroundColor: "white",
      padding: theme.spacing(4),
      boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
      borderRadius: theme.spacing(0.5),
      justifyContent: "center",
    },
  }))();
  const { t } = useTranslation();

  return (
    <>
      <Header
        focus={false}
        title={t("MenuStatistics")}
        titleIcon={<PieChartOutlined />}
      />
      <Content>
        <Box className={classes.root}>
          <StatsComingSoon />
        </Box>
      </Content>
    </>
  );
};

const StatsComingSoon = () => {
  const classes = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(3),
      margin: theme.spacing(2, 0),
      textAlign: "center",
    },
    margin: {
      margin: theme.spacing(1, 0),
    },
  }))();

  return (
    <Box className={classes.root}>
      <PieChartOutlined fontSize="large" />
      <Typography variant="h5" className={classes.margin}>
        {"Bientôt"}
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        className={classes.margin}
      >
        Ici seront affichés les statistiques pour la centrale
      </Typography>
    </Box>
  );
};

export default Statistics;
