import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CotroliaFormControl from "./input/CotroliaFormControl";
import CotroliaTextField from "./input/CotroliaTextField";
import { ContentTwoSided, LeftSide, RightSide } from "../core/ContentTwoSided";
import { Box, Button, useTheme } from "@material-ui/core";
import { emptyError } from "../../utils/model";
import { capitalize, validateEmail } from "../../utils/validation";

const AddContactForm = ({
  lastname = "",
  firstname = "",
  email = "",
  phone = "",
  handleAdd,
  handleCancel,
  addBtnLabel = "",
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [localLastname, setLocalLastname] = useState(lastname ? lastname : "");
  const [localFirstname, setLocalFirstname] = useState(
    firstname ? firstname : "",
  );
  const [localEmail, setLocalEmail] = useState(email ? email : "");
  const [localPhone, setLocalPhone] = useState(phone ? phone : "");
  const [lastnameError, setLastnameError] = useState(emptyError);
  const [firstnameError, setFirstnameError] = useState(emptyError);
  const [emailError, setEmailError] = useState(emptyError);
  const [phoneError, setPhoneError] = useState(emptyError);

  const handleClick = () => {
    const requiredFields = [
      { key: "Lastname", value: localLastname, handler: setLastnameError },
      { key: "Firstname", value: localFirstname, handler: setFirstnameError },
      { key: "Email", value: localEmail, handler: setEmailError },
      { key: "Phone", value: localPhone, handler: setPhoneError },
    ];
    let hasError = false;

    for (let i = 0; i < requiredFields.length; i++) {
      let field = requiredFields[i];

      if (field.value === "") {
        hasError = true;
        field.handler({
          error: true,
          msg: "RequiredField" + field.key,
        });
      }
    }

    if (!hasError) {
      handleAdd(localLastname, localFirstname, localEmail, localPhone);
    }
  };

  const hasError = () => {
    return (
      lastnameError.error ||
      firstnameError.error ||
      emailError.error ||
      emailError.error
    );
  };

  return (
    <>
      <ContentTwoSided>
        <LeftSide>
          {/* Lastname */}
          <CotroliaFormControl style={{ margin: 0 }}>
            <CotroliaTextField
              label={t("Lastname")}
              value={localLastname}
              onChange={(e) => {
                if (e.target.value === "") {
                  setLastnameError({
                    error: true,
                    msg: t("RequiredFieldLastname"),
                  });
                } else {
                  setLastnameError(emptyError);
                }
                setLocalLastname(e.target.value.toUpperCase());
              }}
              required
              error={lastnameError.error}
              helperText={lastnameError.msg}
            />
          </CotroliaFormControl>

          {/* Firstname */}
          <CotroliaFormControl style={{ margin: 0 }}>
            <CotroliaTextField
              label={t("Firstname")}
              value={localFirstname}
              onChange={(e) => {
                if (e.target.value === "") {
                  setFirstnameError({
                    error: true,
                    msg: t("RequiredFieldFirstname"),
                  });
                } else {
                  setFirstnameError(emptyError);
                }
                setLocalFirstname(capitalize(e.target.value));
              }}
              required
              error={firstnameError.error}
              helperText={firstnameError.msg}
            />
          </CotroliaFormControl>
        </LeftSide>
        <RightSide>
          {/* Phone */}
          <CotroliaFormControl style={{ margin: 0 }}>
            <CotroliaTextField
              label={t("Phone")}
              value={localPhone}
              onChange={(e) => {
                if (e.target.value === "") {
                  setPhoneError({
                    error: true,
                    msg: t("RequiredFieldPhone"),
                  });
                } else {
                  setPhoneError(emptyError);
                }
                setLocalPhone(e.target.value);
              }}
              required
              error={phoneError.error}
              helperText={phoneError.msg}
            />
          </CotroliaFormControl>

          {/* Email */}
          <CotroliaFormControl style={{ margin: 0 }}>
            <CotroliaTextField
              label={t("Email")}
              value={localEmail}
              onChange={(e) => {
                if (e.target.value === "") {
                  setEmailError({
                    error: true,
                    msg: t("RequiredFieldEmail"),
                  });
                } else if (!validateEmail(e.target.value)) {
                  setEmailError({
                    error: true,
                    msg: "InvalidEmail",
                  });
                } else {
                  setEmailError(emptyError);
                }
                setLocalEmail(e.target.value);
              }}
              required
              error={emailError.error}
              helperText={emailError.msg}
            />
          </CotroliaFormControl>
        </RightSide>
      </ContentTwoSided>
      <Box
        display="flex"
        justifyContent="flex-end"
        style={{ marginTop: theme.spacing(1) }}
      >
        <Box marginRight={2}>
          <Button color="secondary" onClick={handleCancel}>
            {t("Cancel")}
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            disabled={hasError()}
          >
            {addBtnLabel === "" ? t("Add") : addBtnLabel}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddContactForm;
