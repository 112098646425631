import React from "react";
import { useTranslation } from "react-i18next";
import { FormHelperText } from "@material-ui/core";
import CotroliaFormControl from "../../../../components/core/input/CotroliaFormControl";
import CotroliaAddress from "../../../../components/core/input/CotroliaAddress";
import { Address } from "../../../companies/types/CompaniesTypes";

const FieldDistributorAddress: React.FC<{
  error?: string;
  value?: Address;
  onChange: (address: Address) => void;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <CotroliaFormControl style={{ margin: 0 }} error={!!props.error}>
      <CotroliaAddress
        label={t("DistributorAddress")}
        defaultAddress={props.value}
        onChange={(address: Address) => {
          props.onChange && props.onChange(address);
        }}
        required
        error={!!props.error}
      />
      <FormHelperText variant="outlined">{props.error ?? ""}</FormHelperText>
    </CotroliaFormControl>
  );
};

export default FieldDistributorAddress;
