import React, { useCallback, useState } from "react";
import axios, { AxiosRequestConfig } from "axios";

interface ApiContextType {
  apiError?: ApiError;
  setApiError: (error?: ApiError) => void;
  setBaseUrl: (baseUrl: string) => void;
  call: (
    endpoint: string,
    customConfig?: AxiosRequestConfig,
  ) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Promise<any>;
}

export interface ApiError extends Error {
  status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export const ApiContext = React.createContext<ApiContextType>(
  {} as ApiContextType,
);

export const ApiProvider: React.FC = (props) => {
  const [error, setError] = useState<ApiError>();
  const [baseUrl, setBaseUrl] = useState<string>(import.meta.env.VITE_API_URL);

  const call = useCallback(
    async (endpoint: string, customConfig?: AxiosRequestConfig) => {
      const body = customConfig?.data;
      const headers: HeadersInit = { "content-type": "application/json" };
      const config: AxiosRequestConfig = {
        method: body ? "POST" : "GET",
        ...customConfig,
        headers: {
          ...headers,
          ...customConfig?.headers,
        },
        withCredentials: true,
        validateStatus: function () {
          return true;
        },
      };
      if (body) {
        config.data = body;
      }
      const response = await axios(`${baseUrl}${endpoint}`, config);
      if (response.status === 204) {
        return Promise.resolve({});
      } else if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        const callError: ApiError = {
          name: "API Error",
          message: response.statusText,
          status: response.status,
          data: response.data,
        };
        setError(callError);
        return Promise.reject(callError);
      }
    },
    [baseUrl],
  );

  return (
    <ApiContext.Provider
      value={{
        apiError: error,
        setApiError: setError,
        setBaseUrl: (baseUrl: string) => setBaseUrl(baseUrl),
        call,
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};
