import React from "react";
import { Box, Button, Hidden, makeStyles, Typography } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    color: theme.palette.grey[400],
  },
  btn: {
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(2),
  },
  left: {
    marginRight: theme.spacing(2),
  },
  line: {
    border: `1px solid ${theme.palette.grey[400]}`,
    flexGrow: 1,
  },
}));

const DividerWithIcon = ({ title, action, actionName, icon }) => {
  const classes = useStyle();
  return (
    <Box className={classes.root}>
      <Box className={classes.left}>
        <Typography variant="button">{title}</Typography>
      </Box>
      <Box className={classes.line}></Box>
      <Hidden smDown>
        <Button onClick={action} endIcon={icon} className={classes.btn}>
          {actionName}
        </Button>
      </Hidden>
    </Box>
  );
};

export default DividerWithIcon;
