import React from "react";
import InterFormControl from "../../InterFormControl";
import { Trans } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  inputBackground: {
    width: "100%",
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
    margin: 0,
    boxSizing: "border-box",
    paddingLeft: 12,
    paddingRight: 12,
  },
}));
const FieldAcceptPackaging: React.FC<{
  error?: string;
  onChange?: () => void;
  defaultInfos?: string;
}> = (props) => {
  const classes = useStyle();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <>
      <InterFormControl
        className={classes.inputBackground}
        error={!!props.error}
      >
        {!!intervention.packaging.complementaryPartInfos && (
          <FormControlLabel
            label={
              <Trans
                i18nKey="AcceptComplementaryPart"
                components={{ bold: <strong /> }}
              />
            }
            control={
              <Checkbox
                color="primary"
                checked={intervention.packaging.complementaryPart}
                onChange={() => {
                  setIntervention({
                    ...intervention,
                    packaging: {
                      ...intervention.packaging,
                      complementaryPart:
                        !intervention.packaging.complementaryPart,
                    },
                  });
                  props.onChange && props.onChange();
                }}
              />
            }
          />
        )}
        <FormControlLabel
          label={
            <Trans
              i18nKey="AcceptPackingInstructions"
              components={{ bold: <strong /> }}
            />
          }
          control={
            <Checkbox
              color="primary"
              checked={intervention.packaging.instructions}
              onChange={() => {
                setIntervention({
                  ...intervention,
                  packaging: {
                    ...intervention.packaging,
                    instructions: !intervention.packaging.instructions,
                  },
                });
                props.onChange && props.onChange();
              }}
            />
          }
        />
      </InterFormControl>
      {props.error && (
        <FormHelperText variant="outlined" error>
          {props.error ?? ""}
        </FormHelperText>
      )}
    </>
  );
};

export default FieldAcceptPackaging;
