import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import CotroliaInputLabel from "../../../../components/core/input/CotroliaInputLabel";
import CotroliaSelect from "../../../../components/core/input/CotroliaSelect";
import { Clear } from "@material-ui/icons";
import { useAuth } from "../../../auth/hooks/useAuth";
import { useUsers } from "../../../user/hooks/useUsers";
import { User } from "../../../user/types/userTypes";

const FilterAuthor: React.FC<{
  value?: string | number;
  onChange: (value?: string) => void;
}> = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { getCompanyUsers } = useUsers();
  const [managers, setManagers] = useState<User[]>([]);

  useEffect(() => {
    getCompanyUsers(user!.thirdparty.id).then((res) => {
      setManagers(res);
    });
  }, [getCompanyUsers, user]);

  return (
    <FormControl variant="filled">
      <CotroliaInputLabel>{t("Manager")}</CotroliaInputLabel>
      <CotroliaSelect
        value={props.value ?? ""}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          props.onChange(e.target.value);
        }}
        endAdornment={
          props.value && (
            <InputAdornment position="end" style={{ marginRight: 20 }}>
              <IconButton onClick={() => props.onChange(undefined)}>
                <Clear />
              </IconButton>
            </InputAdornment>
          )
        }
      >
        {managers &&
          managers.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.lastname + " " + item.firstname}
            </MenuItem>
          ))}
      </CotroliaSelect>
    </FormControl>
  );
};

export default FilterAuthor;
