import React, { useEffect, useState } from "react";

import { Box, makeStyles, MenuItem, Typography } from "@material-ui/core";
import MemoryIcon from "@material-ui/icons/Memory";
import TimeToLeaveIcon from "@material-ui/icons/TimeToLeave";
import PeopleIcon from "@material-ui/icons/People";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import { Skeleton } from "@material-ui/lab";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAlert } from "react-alert";

import Header from "../../../../components/core/Header";
import HeaderActions from "../../../../components/core/HeaderActions";
import Content from "../../../../components/core/layout/Content";
import ToggleSection from "../../../../components/core/ToggleSection";
import CotroliaSelect from "../../../../components/core/input/CotroliaSelect";
import CotroliaFormControl from "../../../../components/core/input/CotroliaFormControl";
import CotroliaInputLabel from "../../../../components/core/input/CotroliaInputLabel";
import CotroliaInputSkeleton from "../../../../components/core/skeleton/CotroliaInputSkeleton";

import InterventionDetailStepper from "./InterventionDetailStepper";
import InterventionDetailShipping from "./InterventionDetailShipping";
import InterventionDetailDescription from "./InterventionDetailDescription";
import InterventionDetailDocument from "./InterventionDetailDocument";
import InterventionDetailNotifications from "./InterventionDetailNotifications";
import InterventionDetailExport from "./InterventionDetailExport";
import InterventionCarDetailDescription from "./InterventionCarDetailDescription";
import { useConfiguration } from "../../../../hooks/useConfiguration";
import { useInterventions } from "../../hooks/useInterventions";
import { useDevice } from "../../../../hooks/useDevice";

const useStyle = makeStyles((theme) => ({
  titleRightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: theme.spacing(2),
  },
}));

/**
 * Display the intervention detail
 *
 * @returns
 */
const LatestIntervention = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const device = useDevice();
  const { id } = useParams();
  const [intervention, setIntervention] = useState(null);
  const alert = useAlert();
  const { getLastIntervention } = useInterventions();

  // We must get the color from the configuration
  const configuration = useConfiguration();

  useEffect(() => {
    getLastIntervention()
      .then((res) => setIntervention(res))
      .catch((err) => {
        console.error(err);
        alert.show(t("InterNotFoundDesc"), {
          title: t("InterNotFound"),
          close: () => {
            alert.remove(alert);
          },
          type: "error",
        });
      });
  }, [id, alert, t]);

  return (
    <>
      <Header focus={true}>
        <HeaderActions>
          <Box display="flex" alignItems="center">
            <Box className={classes.titleRightContainer}>
              <Typography variant="h4">
                {intervention ? intervention.ref : <Skeleton width={200} />}
              </Typography>
              <Typography variant="subtitle1">
                {intervention ? (
                  t("YourRef") +
                  " : " +
                  (intervention.customerRef
                    ? intervention.customerRef
                    : t("NoCustomerRef"))
                ) : (
                  <Skeleton width={250} />
                )}
              </Typography>
            </Box>
            {!device.isMobile && <MemoryIcon />}
          </Box>
        </HeaderActions>
      </Header>
      <Content>
        {/* Notification section */}
        {intervention && (
          <InterventionDetailNotifications
            status={parseInt(intervention.status)}
            paymentMethod={intervention.paymentMethod}
            technicalInfo={intervention.technicalInfo}
          />
        )}

        {/* Status section */}
        <ToggleSection
          title={t("Status")}
          icon={<InfoOutlinedIcon />}
          description={t("StatusDesc")}
          defaultExpanded={true}
        >
          <InterventionDetailStepper intervention={intervention} />
        </ToggleSection>

        {/* Technical contact section */}
        <ToggleSection
          title={t("TechnicalContact")}
          icon={<PeopleIcon />}
          description={t("TechnicalContactDesc")}
          defaultExpanded={true}
        >
          {intervention ? (
            <CotroliaFormControl variant="filled">
              <CotroliaInputLabel>{t("YourTechnicalTeam")}</CotroliaInputLabel>

              <CotroliaSelect
                value={
                  intervention.technicalContact
                    ? intervention.technicalContact.id
                    : "-1"
                }
                disabled
              >
                <MenuItem
                  value={
                    intervention.technicalContact
                      ? intervention.technicalContact.id
                      : "-1"
                  }
                >
                  {intervention.technicalContact
                    ? intervention.technicalContact.lastname +
                      " " +
                      intervention.technicalContact.firstname
                    : t("NoTechnicalContact")}
                </MenuItem>
              </CotroliaSelect>
            </CotroliaFormControl>
          ) : (
            <CotroliaInputSkeleton />
          )}
        </ToggleSection>

        {/* Description section */}
        <ToggleSection
          title={t("InterDescription")}
          icon={<MemoryIcon />}
          description={t("InterDescription")}
          defaultExpanded={true}
        >
          <InterventionDetailDescription intervention={intervention} />
        </ToggleSection>

        {/* Car Description section */}
        {configuration.sector === "1" && (
          <ToggleSection
            title={t("CarDescription")}
            icon={<TimeToLeaveIcon />}
            description={t("CarDescription")}
            defaultExpanded={true}
          >
            <InterventionCarDetailDescription intervention={intervention} />
          </ToggleSection>
        )}

        {/* Transport & Shipping section */}
        <ToggleSection
          title={t("TransportShipping")}
          icon={<SystemUpdateAltIcon />}
          description={t("TransportShippingDesc")}
          defaultExpanded={true}
        >
          <InterventionDetailShipping intervention={intervention} />
        </ToggleSection>

        {/* Transport & Shipping Return section */}
        <ToggleSection
          title={t("TransportExport")}
          icon={<OpenInBrowserIcon />}
          description={t("TransportExportDesc")}
          defaultExpanded={true}
        >
          <InterventionDetailExport intervention={intervention} />
        </ToggleSection>

        {/* Documents section */}
        <ToggleSection
          title={t("LastInterventionDocumentsTitle")}
          icon={<AttachFileIcon />}
          description={t("DocumentsDesc")}
          defaultExpanded={true}
        >
          <InterventionDetailDocument intervention={intervention} />
        </ToggleSection>
      </Content>
    </>
  );
};

export default LatestIntervention;
