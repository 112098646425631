import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    "& .MuiAccordionSummary-expandIcon": {
      color: theme.palette.primary.main,
    },
  },
  title: {
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    "& > svg": {
      marginRight: theme.spacing(1),
    },
  },
  header: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  desc: {
    color: theme.palette.text.secondary,
    opacity: 0.64,
    "& > *": {
      margin: `0 ${theme.spacing(1)}px`,
      textAlign: "end",
      [theme.breakpoints.down("sm")]: {
        textAlign: "start",
      },
    },
  },
  details: {
    flexDirection: "column",
  },
}));

const ToggleSection = ({
  title,
  icon,
  description = "",
  expanded = undefined,
  defaultExpanded = false,
  children,
}) => {
  const classes = useStyle();
  return (
    <Accordion
      className={classes.container}
      expanded={expanded}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container className={classes.header}>
          <Grid className={classes.title} xs={12} sm={6}>
            {icon}
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid className={classes.desc} xs={12} sm={6}>
            <Typography variant="subtitle1">{description}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default ToggleSection;
