import React from "react";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import CotroliaDatePicker from "../../../../../components/core/input/CotroliaDatePicker";
import moment from "moment";
import InterFormControl from "../../InterFormControl";
import { FormHelperText, Typography, useTheme } from "@material-ui/core";
import { ShippingMethod } from "../../../types/NewInterventionFormTypes";

const FieldPickupDate: React.FC<{
  error?: string;
  onChange?: () => void;
  onInputError?: (message: string) => void;
}> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();

  const prepareMinDate = () => {
    let minDate = moment();
    // If current date is today after 12h, we set it to the next day
    if (minDate.hour() >= 12) {
      minDate = minDate.add(1, "days");
    }
    return minDate.toDate();
  };
  return (
    <>
      {intervention.shipping.method === ShippingMethod.Picking && (
        <InterFormControl error={!!props.error}>
          <Typography
            variant="subtitle1"
            style={{
              marginBottom: theme.spacing(1.5),
              color: theme.palette.primary.main,
            }}
          >
            {t("PickupDateTitle")}
          </Typography>
          <CotroliaDatePicker
            required
            label={t("PickupDate")}
            value={intervention.shipping.pickupDate}
            onChange={(newValue: moment.Moment) => {
              setIntervention({
                ...intervention,
                shipping: {
                  ...intervention.shipping,
                  pickupDate: newValue,
                },
              });
              props.onChange && props.onChange();
            }}
            minDate={prepareMinDate()}
            onError={(error: string) => {
              if (!!error && props.error !== error) {
                props.onInputError && props.onInputError(error);
              }
            }}
            error={!!props.error}
            FormHelperTextProps={{ hidden: true }}
          />
          {props.error && (
            <FormHelperText variant="outlined">{props.error}</FormHelperText>
          )}
        </InterFormControl>
      )}
    </>
  );
};

export default FieldPickupDate;
