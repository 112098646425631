import { Box, Button, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CotroliaFormControl from "./input/CotroliaFormControl";
import CotroliaInputLabel from "./input/CotroliaInputLabel";
import CotroliaSelect from "./input/CotroliaSelect";
import { useAuth } from "../../features/auth/hooks/useAuth";

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    alignItems: "center",
  },
  or: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    opacity: 0.64,
  },
  select: {
    flexGrow: "1",
    width: "100%",
  },
  spacer: { margin: `0 ${theme.spacing(2)}px` },
  btn: { margin: ` 0 ${theme.spacing(2)}px` },
}));

const SelectOrAddContact = ({
  required = false,
  selected,
  onSelect,
  onClick,
  excludeList = [],
  withoutAdd = false,
  ...props
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [contacts, setContacts] = useState(null);
  const { user } = useAuth();

  const handleChange = (e) => {
    onSelect(e.target.value);
  };

  useEffect(() => {
    if (user && user.thirdparty) {
      const contactList = user.thirdparty.contactList.filter((c) => !c.type);
      if (contactList) {
        let newContacts = [];

        for (let i = 0; i < contactList.length; i++) {
          const elem = contactList[i];

          const contactFormated = {
            id: elem.id,
            email: elem.email,
            firstname: elem.firstname,
            lastname: elem.lastname,
            phone: elem.phone,
          };

          if (!newContacts.some((el) => el.id === contactFormated.id)) {
            newContacts.push(contactFormated);
          }
        }
        setContacts(newContacts);
      }
    }
  }, [user]);

  return (
    <Box className={classes.container}>
      <Box className={classes.select}>
        <CotroliaFormControl
          variant="filled"
          required={required ? true : false}
        >
          <CotroliaInputLabel>{t("SelectMember")}</CotroliaInputLabel>
          <CotroliaSelect
            value={selected ? selected : ""}
            onChange={handleChange}
          >
            {contacts &&
              contacts.map((elem) => {
                if (!excludeList.some((el) => el.id === elem.id)) {
                  return (
                    <MenuItem key={elem.id} value={elem.id}>
                      {elem.lastname} {elem.firstname}
                    </MenuItem>
                  );
                }
                return null;
              })}
          </CotroliaSelect>
        </CotroliaFormControl>
      </Box>
      {!withoutAdd && (
        <>
          <Box className={classes.spacer}>
            <Typography variant="button" className={classes.or}>
              {t("Or")}
            </Typography>
          </Box>
          <Box className={classes.btn} onClick={onClick}>
            <Button>{t("AddContact")}</Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SelectOrAddContact;
