import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import FrenchTranslations from "./locales/fr/translation.json";
import EnglishTranslations from "./locales/en/translation.json";
import SpanishTranslations from "./locales/es/translation.json";
import GermanTranslations from "./locales/de/translation.json";
import ItalianTranslations from "./locales/it/translation.json";
import PortugueseTranslations from "./locales/pt/translation.json";
import DutchTranslations from "./locales/nl/translation.json";

i18n
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      convertDetectedLanguage: "Iso15897", // convert lang code to format "xx_XX" instead of "xx-XX"
    },
    resources: {
      fr: {
        translation: FrenchTranslations,
      },
      en: {
        translation: EnglishTranslations,
      },
      es: {
        translation: SpanishTranslations,
      },
      de: {
        translation: GermanTranslations,
      },
      it: {
        translation: ItalianTranslations,
      },
      nl: {
        translation: DutchTranslations,
      },
      pt: {
        translation: PortugueseTranslations,
      },
    },
  });

export default i18n;
