import { FormControlProps } from "@material-ui/core";
import React from "react";
import CotroliaFormControl from "../../../components/core/input/CotroliaFormControl";

const InterFormControl: React.FC<FormControlProps> = ({ style, ...props }) => {
  return (
    <CotroliaFormControl style={{ margin: 0, ...style }} {...props}>
      {props.children}
    </CotroliaFormControl>
  );
};

export default InterFormControl;
