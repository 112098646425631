import { useEffect } from "react";
import { useNewInterventionForm } from "../context/NewInterventionContext";

export const useStepValidator = (validator: () => boolean) => {
  const { stepState, setStepState } = useNewInterventionForm();

  useEffect(() => {
    if (stepState === "tovalidate") {
      const isValid = validator();
      setStepState(isValid ? "validated" : "normal");
    }
  }, [stepState]);
};
